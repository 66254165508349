import React from "react";
import PropTypes from "prop-types";
import { t } from "ttag";
import { connect } from "react-redux";

import { updateSettings } from "metabase/admin/settings/settings";
import SettingsBatchForm from "./SettingsBatchForm";

const SettingsWebApiForm = ({ settingValues, onSubmit, ...props }) => {
  const handleSubmit = React.useCallback(
    values => onSubmit(values),
    [onSubmit],
  );

  return (
    <SettingsBatchForm
      {...props}
      settingValues={settingValues}
      updateSettings={handleSubmit}
      breadcrumbs={[
        [t`Authentication`, "/admin/settings/authentication"],
        [t`Web API`],
      ]}
      layout={[
        {
          title: t`Server Settings`,
          settings: [
            "web-api-enabled",
            "web-api-key",
            "web-api-url",
            "web-api-url-local",
          ],
        },
      ]}
    />
  );
};

SettingsWebApiForm.propTypes = {
  settingValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSubmit: updateSettings,
};

export default connect(null, mapDispatchToProps)(SettingsWebApiForm);
