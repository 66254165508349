var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var ON,yha,RN,SN,TN,UN,WN,XN,YN,ZN,bO,cO,dO,eO,fO,gO,hO,jO,kO,zha,Aha,Bha,Cha,Dha,Eha,mO,Fha,Gha,Hha,Iha,oO,pO,qO,rO,Jha,sO,tO,Kha,Lha,Mha,vO,xO,Nha,yO,zO,Oha,Pha,AO,BO,Qha,CO,DO,FO,GO,Rha,HO,Sha,JO,Tha,Uha,KO,MO,Vha,Wha,OO,PO,Xha,Yha,SO,Zha,TO,$ha,WO,XO,YO,aia,bia,cia,ZO,$O,aP,dia,eia,bP,fia,gia,hia,cP,eP,fP,iia,jia,kia,lia,mia,gP,hP,iP,nia,jP,oia,pia,qia,kP,ria,sia,tia,uia,lP,via,mP,wia,oP,pP,qP,rP,sP,xia,wP,yia,zia,xP,yP,Aia,zP,AP,Bia,CP,DP,EP,GP,HP,Cia,IP,Dia,Eia,Fia,JP,KP,LP,OP,
Gia,QP,RP,SP,TP,Hia,Iia,Jia,VP,WP,Kia,XP,Lia,Mia,Nia,Oia,aQ,Pia,Qia,bQ,cQ,dQ,Ria,eQ,fQ,gQ,Sia,hQ,Tia,Uia,iQ,jQ,kQ,mQ,nQ,Via,pQ,qQ,rQ,sQ,Wia,uQ,vQ,Xia,xQ,yQ,zQ,AQ,BQ,CQ,Yia,Zia,$ia,aja,bja,EQ,cja,dja,FQ,GQ,HQ,eja,IQ,fja,JQ,gja,hja,KQ,ija,jja,kja,lja,MQ,NQ,mja,nja,oja,QQ,pja,RQ,qja,SQ,rja,UQ,VQ,sja,WQ,XQ,YQ,ZQ,$Q,tja,aR,uja,bR,vja,wja,cR,dR,eR,xja,yja,fR,zja,Aja,gR,Bja,Cja,Dja,Eja,jR,Fja,Gja,Hja,Ija,Jja,Kja,Lja,nR,oR,pR,qR,rR,Mja,sR,tR,uR,vR,Nja,Oja,Pja,Qja,wR,xR,yR,Rja,zR,gka,AR,Sja,Tja,BR,Uja,Vja,
CR,Wja,ER,FR,Xja,GR,Yja,IR,JR,Zja,KR,LR,$ja,MR,aka,NR,bka,PR,QR,RR,SR,cka,TR,dka,UR,eka,VR,WR,ZR,fka,jka,hka,aS,bS,ika,cS,ela,kka,lka,dS,mka,eS,nka,gS,hS,oka,pka,lS,mS,qka,nS,oS,pS,qS,rka,ska;ON=function(a,b){if(null!=a&&null!=a.Td)a=a.Td(a,b);else{var c=ON[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=ON._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("CoreSpec.checker",a);}return a};
yha=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.lM,a,$CLJS.oM,!1,$CLJS.mM,$CLJS.cj.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.PN=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Dm,a,$CLJS.gi(b)),b)};$CLJS.QN=function(a,b){return new $CLJS.gN(a,!1,b,null,null,null)};RN=function(a){return new $CLJS.YM(a,null,null,null)};
SN=function(a,b,c,d){this.Nc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};TN=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return TN.l(0<b.length?new $CLJS.z(b.slice(0),0,null):null)};UN=function(a,b,c,d,e){this.kc=a;this.pc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};WN=function(a,b,c){return VN.l($CLJS.G([a,b,$CLJS.ag(!0),c]))};XN=function(a,b,c,d){this.zc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
YN=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Jz,a)):$CLJS.Nb($CLJS.Jz,a)))throw Error($CLJS.UL("Not an IDeref: %s",$CLJS.G([a])));return new XN(a,null,null,null)};ZN=function(a,b,c,d){this.X=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.$N=function(a){return new ZN(a,null,null,null)};$CLJS.aO=function(a){return $CLJS.Lm.h($CLJS.hM,yha(function(b,c){return ON($CLJS.MM(b),c)},a))};
bO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.gN($CLJS.$N(d),!0,a,null,null,null);case "rest":return $CLJS.UM(d,a);default:return $CLJS.QN(b,a)}}else return $CLJS.QN(b,a)};
cO=function(a,b){return $CLJS.Mg($CLJS.nf($CLJS.QN($CLJS.QM(a),a),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=bO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(bO(n,g),
e($CLJS.zd(f)))}return null}},null,null)}($CLJS.vu(2,2,b))}()))};dO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
eO=function(a){return $CLJS.UM($CLJS.R.h(VN,$CLJS.Ve($CLJS.qg,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(dO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(dO,l),f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.bv.h(", ",$CLJS.rg.h($CLJS.C,a))].join(""))};fO=function(a){return $CLJS.WM(a,$CLJS.A,"Non-empty")};gO=function(a){return $CLJS.A(a)?$CLJS.R.h($CLJS.Qu,a):!0};hO=function(a){return"string"===typeof a?$CLJS.ki.h(Date.parse(a),NaN):null};
$CLJS.qM.prototype.Td=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.vM.prototype.Td=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Sb(function(f,g){return $CLJS.Iga(g,b,f)},function(f){return $CLJS.fM($CLJS.aM(d,f,new $CLJS.Yi(function(){var g=$CLJS.WL(f);return c.bc.g?c.bc.g(g):c.bc.call(null,g)}),null))},$CLJS.lf(c.options));return $CLJS.m(c.Ub)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.gM(g))return g;f=$CLJS.m($CLJS.oM.g(b))?g:f;f=c.Ub.g?c.Ub.g(f):c.Ub.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.DM.prototype.Td=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.oM.g(b))?c.sc:function(){return null},e=$CLJS.BM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.cj.g($CLJS.Lg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.Xf($CLJS.gM,l);return $CLJS.m(n)?$CLJS.fM(c.dc.j?c.dc.j(f,l,g):c.dc.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.iO={};jO={};kO={};$CLJS.h=SN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Nc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pN,this.Nc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.pN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Nc,b.Nc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pN,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new SN(this.Nc,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.pN,b):$CLJS.O.call(null,$CLJS.pN,b))?new SN(c,this.F,this.m,null):new SN(this.Nc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.pN,this.Nc)],null),this.m))};$CLJS.h.O=function(a,b){return new SN(this.Nc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.rM($CLJS.jM(this,function(b){return $CLJS.Ie(a.Nc,b)},function(b){return new $CLJS.gf(null,a.Nc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.rha,this.Nc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};TN.l=function(a){return new SN($CLJS.si(a),null,null,null)};TN.v=0;
TN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=UN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.kc;case "error-symbol":return this.pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rN,this.kc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,this.pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rN,$CLJS.qN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.kc,b.kc)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.rN,null,$CLJS.qN,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new UN(this.kc,this.pc,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.rN,b):$CLJS.O.call(null,$CLJS.rN,b))?new UN(c,this.pc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.qN,b):$CLJS.O.call(null,$CLJS.qN,b))?new UN(this.kc,c,this.F,this.m,null):new UN(this.kc,this.pc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.rN,this.kc),new $CLJS.Je($CLJS.qN,this.pc)],null),this.m))};
$CLJS.h.O=function(a,b){return new UN(this.kc,this.pc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.wM($CLJS.LM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.sM,u,$CLJS.cm,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.sM,l,$CLJS.cm,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a.kc)}(),function(b){var c=a.pc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.kc))?$CLJS.Ui.g($CLJS.XL($CLJS.C($CLJS.C(a.kc)))):$CLJS.Uga;return new $CLJS.gf(null,c,new $CLJS.gf(null,b,null,1,null),2,null)})};
$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.Vga,$CLJS.qg.h($CLJS.sg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.XL(b)),$CLJS.NM(a)],null)},$CLJS.G([this.kc])),$CLJS.m(this.pc)?new $CLJS.S(null,1,5,$CLJS.T,[this.pc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
var VN=function VN(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return VN.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
VN.l=function(a){if(!$CLJS.A(a)||!($CLJS.Zf($CLJS.E(a))||$CLJS.$d(a)instanceof $CLJS.w))throw Error($CLJS.UL("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new UN($CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.VL(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Tu)?$CLJS.ag(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.VL(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Tu)?$CLJS.ag(!0):l,f],null);return $CLJS.nf(l,d($CLJS.zd(e)))}return null}},
null,null)}($CLJS.vu(2,2,a))}()),$CLJS.Zf($CLJS.E(a))?null:$CLJS.$d(a),null,null,null)};VN.v=0;VN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=XN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.zc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tN,this.zc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.tN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.zc,b.zc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.tN,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new XN(this.zc,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.tN,b):$CLJS.O.call(null,$CLJS.tN,b))?new XN(c,this.F,this.m,null):new XN(this.zc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.tN,this.zc)],null),this.m))};$CLJS.h.O=function(a,b){return new XN(this.zc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.xM($CLJS.LM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.cm,$CLJS.r(this.zc)],null)],null))};
$CLJS.h.Fa=function(){if(this.zc instanceof $CLJS.ud){var a=$CLJS.fe(this.zc);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.Dk);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.Ui.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.gf(null,$CLJS.Jl,new $CLJS.gf(null,a,null,1,null),2,null)}else a=$CLJS.sha;return new $CLJS.gf(null,$CLJS.iha,new $CLJS.gf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=ZN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.cm],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.cm,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new ZN(this.X,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.cm,b):$CLJS.O.call(null,$CLJS.cm,b))?new ZN(c,this.F,this.m,null):new ZN(this.X,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.cm,this.X)],null),this.m))};$CLJS.h.O=function(a,b){return new ZN(this.X,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.xM($CLJS.LM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.sM,$CLJS.Cb,$CLJS.cm,$CLJS.QM(null)],null),new $CLJS.k(null,1,[$CLJS.cm,this.X],null)],null))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.jha,new $CLJS.gf(null,$CLJS.NM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
zha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);Aha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.lO=new $CLJS.N(null,"right-join","right-join",-56349359);Bha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);Cha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
Dha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);Eha=new $CLJS.N(null,"from","from",1815293044);mO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Fha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Gha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.nO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Iha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);oO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);pO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);qO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);rO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);Jha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
sO=new $CLJS.w(null,"stddev","stddev",775056588,null);tO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.uO=new $CLJS.N(null,"query","query",-1288509510);Kha=new $CLJS.N(null,"lat-field","lat-field",-830652957);Lha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);Mha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);vO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.wO=new $CLJS.N(null,"fields","fields",-1932066230);xO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Nha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);yO=new $CLJS.N(null,"unary","unary",-989314568);zO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Oha=new $CLJS.N(null,"lon-min","lon-min",-787291357);Pha=new $CLJS.N(null,"match","match",1220059550);
AO=new $CLJS.w(null,"count-where","count-where",2025939247,null);BO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Qha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);CO=new $CLJS.w(null,"sum","sum",1777518341,null);DO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.EO=new $CLJS.N("date","range","date/range",1647265776);FO=new $CLJS.w(null,"between","between",-1523336493,null);
GO=new $CLJS.N(null,"clause-form","clause-form",1820463737);Rha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);HO=new $CLJS.w(null,"field","field",338095027,null);Sha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.IO=new $CLJS.N(null,"filter","filter",-948537934);JO=new $CLJS.w(null,"not-null","not-null",313812992,null);Tha=new $CLJS.N(null,"template-tag","template-tag",310841038);
Uha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);KO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.LO=new $CLJS.N(null,"context","context",-830191113);MO=new $CLJS.w(null,"get-year","get-year",704520253,null);Vha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.NO=new $CLJS.N(null,"parameters","parameters",-1229919748);Wha=new $CLJS.N(null,"json-download","json-download",-971130133);
OO=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);PO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Xha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Yha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.QO=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.RO=new $CLJS.N("date","month-year","date/month-year",1948031290);
SO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Zha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);TO=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.UO=new $CLJS.N(null,"joins","joins",1033962699);$ha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.VO=new $CLJS.N(null,"source-field","source-field",933829534);WO=new $CLJS.w(null,"Field","Field",430385967,null);
XO=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);YO=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);aia=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);bia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);cia=new $CLJS.N(null,"items","items",1031954938);ZO=new $CLJS.N(null,"more","more",-2058821800);
$O=new $CLJS.N(null,"first-clause","first-clause",-20953491);aP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);dia=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);eia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);bP=new $CLJS.w(null,"contains","contains",-1977535957,null);fia=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
gia=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);hia=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);cP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.dP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);eP=new $CLJS.w(null,"is-null","is-null",-356519403,null);fP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);iia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
jia=new $CLJS.N(null,"required","required",1807647006);kia=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);lia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);mia=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);gP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);hP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);iP=new $CLJS.N("string","contains","string/contains",1602423827);
nia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);jP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);oia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);pia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);qia=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
kP=new $CLJS.w(null,"share","share",1051097594,null);ria=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);sia=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);tia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);uia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
lP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);via=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);mP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.nP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);wia=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);oP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
pP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);qP=new $CLJS.w(null,"metric","metric",2049329604,null);rP=new $CLJS.w(null,"concat","concat",-467652465,null);sP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.tP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);xia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.uP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.vP=new $CLJS.N(null,"alias","alias",-2039751630);
wP=new $CLJS.N("location","city","location/city",-1746973325);yia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);zia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);xP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);yP=new $CLJS.N("number","between","number/between",97700581);Aia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);zP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
AP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.BP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Bia=new $CLJS.N(null,"metric-id","metric-id",-686486942);CP=new $CLJS.w(null,"*","*",345799209,null);DP=new $CLJS.w(null,"+","+",-740910886,null);EP=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.FP=new $CLJS.N(null,"breakout","breakout",-732419050);GP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
HP=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Cia=new $CLJS.N(null,"question","question",-1411720117);IP=new $CLJS.w(null,"asc","asc",1997386096,null);Dia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);Eia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);Fia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
JP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);KP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);LP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.MP=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.NP=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);OP=new $CLJS.N("string","ends-with","string/ends-with",302681156);Gia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.PP=new $CLJS.w("s","Str","s/Str",907974338,null);QP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);RP=new $CLJS.w(null,"and","and",668631710,null);SP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);TP=new $CLJS.w(null,"round","round",-645002441,null);Hia=new $CLJS.N(null,"to","to",192099007);$CLJS.UP=new $CLJS.N("date","single","date/single",1554682003);Iia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
Jia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);VP=new $CLJS.w(null,"exp","exp",1378825265,null);WP=new $CLJS.w(null,"Filter","Filter",-424893332,null);Kia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);XP=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Lia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
Mia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.YP=new $CLJS.N(null,"source-table","source-table",-225307692);Nia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.ZP=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);Oia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.$P=new $CLJS.N(null,"metric","metric",408798077);
aQ=new $CLJS.w(null,"floor","floor",-772394748,null);Pia=new $CLJS.N(null,"middleware","middleware",1462115504);Qia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);bQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);cQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);dQ=new $CLJS.w(null,"now","now",-9994004,null);Ria=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
eQ=new $CLJS.w(null,"avg","avg",1837937727,null);fQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);gQ=new $CLJS.N(null,"max-results","max-results",-32858165);Sia=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);hQ=new $CLJS.w(null,"case","case",-1510733573,null);Tia=new $CLJS.w("js","Date","js/Date",946858373,null);Uia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
iQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);jQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);kQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.lQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);mQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);nQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);Via=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.oQ=new $CLJS.w("s","pred","s/pred",-727014287,null);pQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);qQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);rQ=new $CLJS.w(null,"abs","abs",1394505050,null);sQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Wia=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.tQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
uQ=new $CLJS.N(null,"date","date",-1463434462);vQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.wQ=new $CLJS.N(null,"left-join","left-join",-672831855);Xia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);xQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);yQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);zQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
AQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);BQ=new $CLJS.w(null,"or","or",1876275696,null);CQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.DQ=new $CLJS.N(null,"field","field",-1302436500);Yia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Zia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);$ia=new $CLJS.N(null,"constraints","constraints",422775616);
aja=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);bja=new $CLJS.N(null,"csv-download","csv-download",2141432084);EQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);cja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);dja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
FQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);GQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);HQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);eja=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);IQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
fja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);JQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);gja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);hja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);KQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
ija=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);jja=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);kja=new $CLJS.N(null,"display_name","display_name",-1494335013);lja=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.LQ=new $CLJS.N(null,"database","database",1849087575);MQ=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
NQ=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);mja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.OQ=new $CLJS.N(null,"expressions","expressions",255689909);nja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);oja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.PQ=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
QQ=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);pja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);RQ=new $CLJS.w(null,"get-day","get-day",1768100384,null);qja=new $CLJS.w(null,"Fields","Fields",430617156,null);SQ=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.TQ=new $CLJS.N(null,"native","native",-613060878);rja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
UQ=new $CLJS.N(null,"page","page",849072397);VQ=new $CLJS.w(null,"length","length",-2065447907,null);sja=new $CLJS.N(null,"dashboard","dashboard",-631747508);WQ=new $CLJS.w(null,"get-week","get-week",752472178,null);XQ=new $CLJS.w(null,"get-month","get-month",1271156796,null);YQ=new $CLJS.w(null,"dimension","dimension",-2111181571,null);ZQ=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
$Q=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);tja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);aR=new $CLJS.w(null,"substring","substring",-1513569493,null);uja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);bR=new $CLJS.N(null,"internal","internal",-854870097);vja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
wja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);cR=new $CLJS.N(null,"declared","declared",92336021);dR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);eR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);xja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);yja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
fR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);zja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Aja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);gR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.hR=new $CLJS.N(null,"template-tags","template-tags",1853115685);Bja=new $CLJS.N(null,"public-question","public-question",629369976);
Cja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.iR=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);Dja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Eja=new $CLJS.N(null,"binary","binary",-1802232288);jR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.kR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.lR=new $CLJS.N(null,"settings","settings",1556144875);Gja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.mR=new $CLJS.N(null,"inner-join","inner-join",659431740);Hja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);Ija=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);Jja=new $CLJS.w("s","eq","s/eq",1021992833,null);
Kja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Lja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);nR=new $CLJS.N(null,"amount","amount",364489504);oR=new $CLJS.w(null,"percentile","percentile",1039342775,null);pR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);qR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);rR=new $CLJS.w(null,"trim","trim",-1880116002,null);
Mja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);sR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);tR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);uR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);vR=new $CLJS.N("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
Oja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Qja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);wR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);xR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);yR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
Rja=new $CLJS.N(null,"lat-min","lat-min",1630784161);zR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);gka=new $CLJS.N(null,"pdf-download","pdf-download",1048402903);AR=new $CLJS.w(null,"inside","inside",-681932758,null);Sja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);Tja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);
BR=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Uja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Vja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);CR=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.DR=new $CLJS.N(null,"category","category",-593092832);Wja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);ER=new $CLJS.w(null,"upper","upper",1886775433,null);
FR=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);Xja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);GR=new $CLJS.N(null,"sugar","sugar",-73788488);Yja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.HR=new $CLJS.N(null,"full-join","full-join",1305476385);IR=new $CLJS.w(null,"power","power",702679448,null);JR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);
Zja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);KR=new $CLJS.w(null,"median","median",-2084869638,null);LR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);$ja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);MR=new $CLJS.N(null,"y","y",-1757859776);aka=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);
NR=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);bka=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.OR=new $CLJS.N(null,"binning","binning",1709835866);PR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);QR=new $CLJS.N(null,"b","b",1482224470);RR=new $CLJS.N(null,"a","a",-2123407586);SR=new $CLJS.w(null,"replace","replace",853943757,null);
cka=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);TR=new $CLJS.w(null,"segment","segment",675610331,null);dka=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);UR=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);eka=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);VR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);
WR=new $CLJS.N("string","starts-with","string/starts-with",1266861170);$CLJS.YR=new $CLJS.N(null,"order-by","order-by",1527318070);ZR=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);fka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);jka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);hka=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);
$CLJS.$R=new $CLJS.N(null,"condition","condition",1668437652);aS=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);bS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);ika=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);cS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);ela=new $CLJS.N(null,"card-name","card-name",-2035606807);
kka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);lka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);dS=new $CLJS.w(null,"log","log",45015523,null);mka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);eS=new $CLJS.w(null,"time","time",-1268547887,null);
nka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.fS=new $CLJS.N(null,"database_type","database_type",-54700895);gS=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);hS=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.iS=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.jS=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.kS=new $CLJS.N("date","all-options","date/all-options",549325958);oka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);pka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);lS=new $CLJS.N("location","state","location/state",-114378652);mS=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);qka=new $CLJS.N(null,"lon-field","lon-field",517872067);
nS=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);oS=new $CLJS.N(null,"numeric","numeric",-1495594714);pS=new $CLJS.w(null,"variable","variable",1359185035,null);qS=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.rS=new $CLJS.N(null,"limit","limit",-1355822363);rka=new $CLJS.w("s","enum","s/enum",-975416934,null);ska=new $CLJS.N(null,"pulse","pulse",-244494476);var sS,tS,uS,tka,uka,vka,wka,xS,yS,xka,yka,zka,zS,Aka,AS,BS,CS,Bka,DS,Cka,ES,FS,Dka,Eka,JS,Fka,MS,OS,PS,QS,RS,SS,VS,XS,ZS,$S,Gka,aT,bT,cT,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,wT,TS,yT,zT,AT,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,OT,NS,RT,ST,TT,UT,VT,WT,XT,YT,ZT,$T,aU,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,mU,Hka,nU,oU,YS,pU,qU,Ika,Jka,rU,xT,PT,tU,uU,vU,wU,xU,yU,zU,AU,BU,CU,DU,EU,FU,GU,HU,IU,JU,KU,Kka,MU,NU,PU,Lka,Mka,QU,Nka,Oka,Pka,Qka,Rka,SU,UU,VU,XU,Ska,Tka,Uka,YU,ZU,Vka,RU,$U,aV,
bV,Wka,Xka,Yka,Zka,$ka,ala,bla,cla,dla;sS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.Yk,null,$CLJS.Pj,null,$CLJS.mk,null,$CLJS.jl,null,$CLJS.am,null,$CLJS.ul,null,$CLJS.Jj,null,$CLJS.tl,null,$CLJS.Uj,null,$CLJS.gk,null],null),null);tS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.vz,null,$CLJS.sz,null,$CLJS.Pj,null,$CLJS.bk,null,$CLJS.tz,null,$CLJS.Rk,null,$CLJS.uz,null],null),null);uS=$CLJS.xz.h(sS,tS);tka=$CLJS.UM($CLJS.R.h(TN,sS),"date-bucketing-unit");
uka=$CLJS.UM($CLJS.R.h(TN,tS),"time-bucketing-unit");$CLJS.vS=$CLJS.UM($CLJS.R.h(TN,uS),"datetime-bucketing-unit");$CLJS.wS=$CLJS.UM($CLJS.R.h(TN,$CLJS.TL.tz.names()),"timezone-id");vka=$CLJS.UM($CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.ZK,null,$CLJS.mk,null,$CLJS.bk,null,$CLJS.am,null,$CLJS.ul,null,$CLJS.fJ,null,$CLJS.II,null,$CLJS.UK,null,$CLJS.XI,null,$CLJS.gk,null,$CLJS.Rk,null],null),null)),"temporal-extract-units");
wka=$CLJS.UM($CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Yk,null,$CLJS.sz,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"datetime-diff-units");xS=$CLJS.UM($CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.IJ,null,$CLJS.vI,null,$CLJS.qJ,null],null),null)),"extract-week-modes");
yS=$CLJS.UM($CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Yk,null,$CLJS.Pj,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"relative-datetime-unit");xka=$CLJS.WM($CLJS.JN,hO,"valid ISO-8601 datetime string literal");yka=$CLJS.WM($CLJS.JN,hO,"valid ISO-8601 datetime string literal");zka=$CLJS.WM($CLJS.JN,hO,"valid ISO-8601 time string literal");
zS=cO($CLJS.rJ,$CLJS.G(["n",$CLJS.IN($CLJS.G([$CLJS.QM($CLJS.mB),$CLJS.EN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,yS],null)]));Aka=cO($CLJS.iJ,$CLJS.G(["n",$CLJS.EN,"unit",yS]));AS=VN.l($CLJS.G([function(a){return $CLJS.Gb(dO($CLJS.FI,a))},cO($CLJS.FI,$CLJS.G(["t",Date,"unit",$CLJS.vS])),function(a){return $CLJS.Zd(a)instanceof Date},cO($CLJS.FI,$CLJS.G(["date",Date,"unit",tka])),$CLJS.Tu,cO($CLJS.FI,$CLJS.G(["datetime",Date,"unit",$CLJS.vS]))]));
BS=cO($CLJS.YD,$CLJS.G(["time",Date,"unit",uka]));CS=VN.l($CLJS.G([$CLJS.bg(dO,$CLJS.FI),AS,hO,yka,hO,xka,$CLJS.Tu,$CLJS.IN($CLJS.G([Date]))]));Bka=VN.l($CLJS.G([$CLJS.bg(dO,$CLJS.YD),BS,hO,zka,$CLJS.Tu,$CLJS.IN($CLJS.G([Date]))]));DS=$CLJS.IN($CLJS.G([Bka,CS]));
eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof AS?new $CLJS.ud(function(){return AS},$CLJS.K(aia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,bR,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QQ,"metabase/mbql/schema.cljc",69,$CLJS.FI,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(AS)?AS.J:null])):null));return $CLJS.m(a)?a:QQ}(),AS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof zS?new $CLJS.ud(function(){return zS},$CLJS.K(oka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,
$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",29,$CLJS.rJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rJ,$CLJS.tC,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tQ,$CLJS.X(Jja,$CLJS.mB),CQ)),$CLJS.Wj,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,QP))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(zS)?zS.J:null])):null));return $CLJS.m(a)?
a:pQ}(),zS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof BS?new $CLJS.ud(function(){return BS},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,bR,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eS,"metabase/mbql/schema.cljc",27,$CLJS.YD,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.YD,$CLJS.YD,$CLJS.X($CLJS.Fl,Tia),$CLJS.Wj,$CLJS.X($CLJS.Fl,xia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(BS)?BS.J:null])):null));return $CLJS.m(a)?a:eS}(),BS],null)]));Cka=$CLJS.Pf([RN($CLJS.fS),$CLJS.$N($CLJS.JN),RN($CLJS.tJ),$CLJS.$N($CLJS.KN),RN($CLJS.BP),$CLJS.$N($CLJS.LN),RN($CLJS.Wj),$CLJS.$N($CLJS.vS),RN($CLJS.V),$CLJS.$N($CLJS.JN),$CLJS.FN,$CLJS.AN]);
ES=cO($CLJS.ql,$CLJS.G(["value",$CLJS.AN,"type-info",$CLJS.$N(Cka)]));FS=cO($CLJS.zG,$CLJS.G(["expression-name",$CLJS.JN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.SM($CLJS.oe,"map")],null)]));Dka=TN.l($CLJS.G([$CLJS.CI,$CLJS.TK,$CLJS.Pj]));
Eka=function(a){return $CLJS.WM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.WK);c=$CLJS.M.h(c,$CLJS.CI);return $CLJS.F.h(b,$CLJS.CI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.WM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.WK);c=$CLJS.M.h(c,$CLJS.TK);return $CLJS.F.h(b,$CLJS.TK)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Pf([$CLJS.WK,Dka,RN($CLJS.CI),$CLJS.MN,RN($CLJS.TK),$CLJS.WM($CLJS.DN,$CLJS.$f($CLJS.ef),
"bin width must be \x3e\x3d 0."),$CLJS.FN,$CLJS.AN])));$CLJS.GS=function GS(a){switch(arguments.length){case 1:return GS.g(arguments[0]);case 2:return GS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.GS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.CG);b=$CLJS.M.h(b,$CLJS.PQ);return $CLJS.GS.h(a,b)};
$CLJS.GS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.OH(a,$CLJS.OI)?sS:$CLJS.OH(a,$CLJS.XK)?tS:$CLJS.OH(a,$CLJS.SJ)?uS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.GS.v=2;
var HS=$CLJS.WM($CLJS.WM($CLJS.Pf([RN($CLJS.CG),$CLJS.$N($CLJS.KN),RN($CLJS.VO),$CLJS.$N($CLJS.IN($CLJS.G([$CLJS.MN,$CLJS.JN]))),RN($CLJS.PQ),$CLJS.$N($CLJS.vS),RN($CLJS.lQ),$CLJS.$N($CLJS.JN),RN($CLJS.OR),$CLJS.$N(Eka),$CLJS.FN,$CLJS.AN]),$CLJS.GS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.$f($CLJS.WK),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),IS=function(a){return $CLJS.WM(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.CG);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(cO($CLJS.DQ,$CLJS.G(["id-or-name",$CLJS.IN($CLJS.G([$CLJS.MN,$CLJS.JN])),"options",$CLJS.$N(YN(new $CLJS.ud(function(){return HS},nka,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Mha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(HS)?HS.J:null]))))])));$CLJS.WM(IS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
JS=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof FS?new $CLJS.ud(function(){return FS},$CLJS.K(gia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.QO,"metabase/mbql/schema.cljc",60,$CLJS.zG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.zG,$CLJS.jS,$CLJS.X($CLJS.Fl,NR),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,$CLJS.X($CLJS.oQ,$CLJS.ek,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(FS)?FS.J:null])):null));return $CLJS.m(a)?a:$CLJS.QO}(),FS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof IS?
new $CLJS.ud(function(){return IS},$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.al,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],["0.39.0",$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",51,$CLJS.DQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(IS)?IS.J:null])):null));return $CLJS.m(a)?
a:HO}(),IS],null)]));$CLJS.KS=YN(new $CLJS.ud(function(){return JS},Cja,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(JS)?JS.J:null])));
Fka=cO($CLJS.MP,$CLJS.G(["aggregation-clause-index",$CLJS.EN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.SM($CLJS.oe,"map")],null)]));$CLJS.LS=WN($CLJS.bg(dO,$CLJS.MP),Fka,$CLJS.KS);MS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.Ru,null,$CLJS.GK,null,$CLJS.aI,null,$CLJS.VK,null,$CLJS.oI,null,$CLJS.QJ,null,$CLJS.ZI,null,$CLJS.$u,null,$CLJS.BK,null,$CLJS.OJ,null,$CLJS.YK,null],null),null);
OS=VN.l($CLJS.G([$CLJS.Jb,$CLJS.BN,$CLJS.bg(dO,MS),YN(new $CLJS.ud(function(){return NS},bS,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(NS)?NS.J:null]))),$CLJS.bg(dO,$CLJS.ql),ES,$CLJS.Tu,$CLJS.KS]));
PS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.jI,null,$CLJS.SK,null,$CLJS.mm,null,$CLJS.oK,null,$CLJS.gK,null,$CLJS.oJ,null,$CLJS.xu,null,$CLJS.ZH,null,$CLJS.wK,null,$CLJS.cK,null,$CLJS.QJ,null,$CLJS.PK,null,$CLJS.jK,null,$CLJS.VJ,null,$CLJS.mI,null,$CLJS.DI,null,$CLJS.lm,null,$CLJS.tI,null,$CLJS.ZI,null,$CLJS.YH,null,$CLJS.iD,null,$CLJS.EI,null,$CLJS.aK,null,$CLJS.hJ,null,$CLJS.RK,null,$CLJS.GJ,null],null),null);
QS=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.tm,null,$CLJS.jm,null,$CLJS.Bk,null,$CLJS.Tk,null,$CLJS.sm,null,$CLJS.CK,null,$CLJS.Lj,null,$CLJS.gm,null,$CLJS.hm,null],null),null);RS=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.qk,null,$CLJS.zK,null,$CLJS.eK,null,$CLJS.MJ,null,$CLJS.NK,null,$CLJS.QK,null,$CLJS.MI,null,$CLJS.mJ,null,$CLJS.nO,null,$CLJS.QI,null,$CLJS.uK,null,$CLJS.YJ,null,$CLJS.Yl,null,$CLJS.kD,null,$CLJS.CJ,null,$CLJS.$P,null,$CLJS.sK,null],null),null);
SS=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DJ,null,$CLJS.uI,null,$CLJS.LK,null,$CLJS.lm,null,$CLJS.dK,null],null),null);
VS=VN.l($CLJS.G([$CLJS.Eb,$CLJS.DN,$CLJS.bg(dO,PS),YN(new $CLJS.ud(function(){return TS},tR,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(TS)?TS.J:null]))),$CLJS.bg(dO,RS),YN(new $CLJS.ud(function(){return $CLJS.US},xO,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.US)?$CLJS.US.J:null]))),$CLJS.bg(dO,$CLJS.ql),ES,$CLJS.Tu,$CLJS.KS]));
XS=VN.l($CLJS.G([$CLJS.bg(dO,RS),YN(new $CLJS.ud(function(){return $CLJS.US},xO,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.US)?$CLJS.US.J:null]))),$CLJS.bg(dO,$CLJS.ql),ES,$CLJS.bg(dO,SS),YN(new $CLJS.ud(function(){return $CLJS.WS},
AP,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.WS)?$CLJS.WS.J:null]))),$CLJS.Tu,$CLJS.IN($CLJS.G([CS,$CLJS.KS]))]));
ZS=VN.l($CLJS.G([$CLJS.Eb,$CLJS.DN,$CLJS.ve,$CLJS.CN,$CLJS.bg(dO,QS),YN(new $CLJS.ud(function(){return YS},Zha,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hia,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m(YS)?YS.J:null]))),$CLJS.bg(dO,PS),YN(new $CLJS.ud(function(){return TS},
tR,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(TS)?TS.J:null]))),$CLJS.bg(dO,SS),YN(new $CLJS.ud(function(){return $CLJS.WS},AP,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],
[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.WS)?$CLJS.WS.J:null]))),$CLJS.Jb,$CLJS.BN,$CLJS.bg(dO,MS),YN(new $CLJS.ud(function(){return NS},bS,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(NS)?NS.J:null]))),$CLJS.bg(dO,$CLJS.ql),ES,$CLJS.Tu,$CLJS.KS]));$S=WN($CLJS.bg(dO,$CLJS.iJ),Aka,VS);Gka=WN($CLJS.Eb,$CLJS.MN,VS);aT=cO($CLJS.QJ,$CLJS.G(["a",ZS,"b",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,ZS],null)]));bT=cO($CLJS.aI,$CLJS.G(["s",OS,"start",Gka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,VS],null)]));cT=cO($CLJS.iD,$CLJS.G(["s",OS]));dT=cO($CLJS.BK,$CLJS.G(["s",OS]));
eT=cO($CLJS.YK,$CLJS.G(["s",OS]));fT=cO($CLJS.GK,$CLJS.G(["s",OS]));gT=cO($CLJS.Ru,$CLJS.G(["s",OS]));hT=cO($CLJS.$u,$CLJS.G(["s",OS]));iT=cO($CLJS.VK,$CLJS.G(["s",OS,"match",$CLJS.BN,"replacement",$CLJS.BN]));jT=cO($CLJS.oI,$CLJS.G(["a",OS,"b",OS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,OS],null)]));kT=cO($CLJS.OJ,$CLJS.G(["s",OS,"pattern",$CLJS.BN]));lT=cO($CLJS.lm,$CLJS.G(["x",$S,"y",$S,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,$S],null)]));
mT=cO($CLJS.xu,$CLJS.G(["x",VS,"y",$S,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,$S],null)]));nT=cO($CLJS.PK,$CLJS.G(["x",VS,"y",VS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,VS],null)]));oT=cO($CLJS.mm,$CLJS.G(["x",VS,"y",VS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,VS],null)]));pT=cO($CLJS.GJ,$CLJS.G(["x",VS]));qT=cO($CLJS.jI,$CLJS.G(["x",VS]));rT=cO($CLJS.VJ,$CLJS.G(["x",VS]));sT=cO($CLJS.tI,$CLJS.G(["x",VS]));tT=cO($CLJS.mI,$CLJS.G(["x",VS,"y",VS]));uT=cO($CLJS.oJ,$CLJS.G(["x",VS]));
vT=cO($CLJS.ZH,$CLJS.G(["x",VS]));wT=cO($CLJS.RK,$CLJS.G(["x",VS]));TS=YN(new $CLJS.ud(function(){return xT},dia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Wja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(xT)?xT.J:null])));
yT=cO($CLJS.hJ,$CLJS.G(["datetime-x",XS,"datetime-y",XS,"unit",wka]));zT=cO($CLJS.oK,$CLJS.G(["datetime",XS,"unit",vka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,xS],null)]));AT=cO($CLJS.EI,$CLJS.G(["date",XS]));BT=cO($CLJS.SK,$CLJS.G(["date",XS]));CT=cO($CLJS.YH,$CLJS.G(["date",XS]));DT=cO($CLJS.cK,$CLJS.G(["date",XS,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,xS],null)]));ET=cO($CLJS.aK,$CLJS.G(["date",XS]));FT=cO($CLJS.jK,$CLJS.G(["date",XS]));GT=cO($CLJS.wK,$CLJS.G(["datetime",XS]));
HT=cO($CLJS.gK,$CLJS.G(["datetime",XS]));IT=cO($CLJS.DI,$CLJS.G(["datetime",XS]));JT=cO($CLJS.uI,$CLJS.G(["datetime",XS,"to",$CLJS.wS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.wS],null)]));KT=$CLJS.UM($CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Yk,null,$CLJS.sz,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.tz,null,$CLJS.uz,null],null),null)),"arithmetic-datetime-unit");LT=cO($CLJS.dK,$CLJS.G(["datetime",XS,"amount",VS,"unit",KT]));
MT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return cO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.DJ);NT=cO($CLJS.LK,$CLJS.G(["datetime",XS,"amount",VS,"unit",KT]));
OT=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(JQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DP,"metabase/mbql/schema.cljc",51,$CLJS.lm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lm,$CLJS.IG,$CLJS.X($CLJS.Fl,MQ),MR,$CLJS.X($CLJS.Fl,MQ),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,MQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:DP}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},
$CLJS.K(Ria,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[hP,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",67,$CLJS.dK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.dK,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS),nR,$CLJS.X($CLJS.Fl,
qR),$CLJS.Wj,$CLJS.X($CLJS.Fl,zR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(LT)?LT.J:null])):null));return $CLJS.m(a)?a:EQ}(),LT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof NT?new $CLJS.ud(function(){return NT},$CLJS.K(zha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,
$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[hP,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",72,$CLJS.LK,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.LK,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS),nR,$CLJS.X($CLJS.Fl,qR),$CLJS.Wj,$CLJS.X($CLJS.Fl,zR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(NT)?NT.J:null])):null));return $CLJS.m(a)?
a:mS}(),NT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(Tja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.uI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XO,"metabase/mbql/schema.cljc",71,$CLJS.uI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.uI,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS),Hia,$CLJS.X($CLJS.Fl,PR),Eha,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,PR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:XO}(),JT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},
$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.DJ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",45,$CLJS.DJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.DJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?a:dQ}(),MT],null)]));$CLJS.WS=YN(new $CLJS.ud(function(){return OT},Dia,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Iia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(OT)?OT.J:null])));
NS=YN(new $CLJS.ud(function(){return PT},Zja,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Uia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m(PT)?PT.J:null])));
RT=cO($CLJS.Lj,$CLJS.G(["first-clause",YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null]))),"second-clause",YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,
$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null])))],null)]));
ST=cO($CLJS.Bk,$CLJS.G(["first-clause",YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null]))),"second-clause",YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,
$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null])))],null)]));
TT=cO($CLJS.Tk,$CLJS.G(["clause",YN(new $CLJS.ud(function(){return $CLJS.QT},AQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.QT)?$CLJS.QT.J:null])))]));UT=WN($CLJS.bg(dO,$CLJS.rJ),zS,$CLJS.KS);
VT=$CLJS.$N($CLJS.IN($CLJS.G([$CLJS.CN,$CLJS.DN,$CLJS.BN,DS,UT,ZS,ES])));WT=WN($CLJS.bg(dO,$CLJS.ql),ES,$CLJS.IN($CLJS.G([$CLJS.DN,$CLJS.BN,DS,ZS,UT])));XT=cO($CLJS.gm,$CLJS.G(["field",VT,"value-or-field",VT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,VT],null)]));YT=cO($CLJS.CK,$CLJS.G(["field",VT,"value-or-field",VT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,VT],null)]));ZT=cO($CLJS.hm,$CLJS.G(["field",WT,"value-or-field",WT]));
$T=cO($CLJS.jm,$CLJS.G(["field",WT,"value-or-field",WT]));aU=cO($CLJS.tm,$CLJS.G(["field",WT,"value-or-field",WT]));bU=cO($CLJS.sm,$CLJS.G(["field",WT,"value-or-field",WT]));cU=cO($CLJS.JI,$CLJS.G(["field",WT,"min",WT,"max",WT]));dU=cO($CLJS.UI,$CLJS.G(["lat-field",WT,"lon-field",WT,"lat-max",WT,"lon-min",WT,"lat-min",WT,"lon-max",WT]));eU=cO($CLJS.xI,$CLJS.G(["field",$CLJS.KS]));fU=cO($CLJS.nJ,$CLJS.G(["field",$CLJS.KS]));gU=cO($CLJS.KI,$CLJS.G(["field",$CLJS.KS]));
hU=cO($CLJS.$I,$CLJS.G(["field",$CLJS.KS]));iU=$CLJS.Pf([RN($CLJS.$J),$CLJS.CN]);jU=cO($CLJS.uJ,$CLJS.G(["field",OS,"string-or-field",OS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,iU],null)]));kU=cO($CLJS.mK,$CLJS.G(["field",OS,"string-or-field",OS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,iU],null)]));lU=cO($CLJS.KJ,$CLJS.G(["field",OS,"string-or-field",OS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,iU],null)]));
mU=cO($CLJS.vJ,$CLJS.G(["field",OS,"string-or-field",OS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,iU],null)]));Hka=$CLJS.Pf([RN($CLJS.xK),$CLJS.CN]);nU=cO($CLJS.qK,$CLJS.G(["field",$CLJS.KS,"n",$CLJS.IN($CLJS.G([$CLJS.EN,TN.l($CLJS.G([$CLJS.mB,$CLJS.oD,$CLJS.RI]))])),"unit",yS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Hka],null)]));oU=cO($CLJS.nI,$CLJS.G(["segment-id",$CLJS.IN($CLJS.G([$CLJS.MN,$CLJS.JN]))]));
YS=YN(new $CLJS.ud(function(){return pU},Fia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(pU)?pU.J:null])));
pU=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(JR,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,$O,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),vQ,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),fP,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(RT)?RT.J:null])):null));return $CLJS.m(a)?a:RP}(),RT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==
typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(yQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",14,$CLJS.Bk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Bk,$O,$CLJS.X($CLJS.Fl,
$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),vQ,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),fP,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:BQ}(),ST],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K($Q,new $CLJS.k(null,
1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ZL,"metabase/mbql/schema.cljc",15,$CLJS.Tk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,$CLJS.iS,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(TT)?TT.J:
null])):null));return $CLJS.m(a)?a:$CLJS.ZL}(),TT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(HQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KP,"metabase/mbql/schema.cljc",13,$CLJS.hm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.hm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:KP}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(cS,new $CLJS.k(null,1,
[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",14,$CLJS.tm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.tm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(aU)?aU.J:null])):
null));return $CLJS.m(a)?a:JP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(FQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LP,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.jm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:LP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(VR,new $CLJS.k(null,1,
[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",14,$CLJS.sm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(bU)?bU.J:null])):
null));return $CLJS.m(a)?a:SP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.oN,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gm,$CLJS.DQ,$CLJS.X($CLJS.Fl,gS),xR,$CLJS.X($CLJS.Fl,gS),dR,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,gS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:$CLJS.oN}(),XT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},
$CLJS.K(DO,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",14,$CLJS.CK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.CK,$CLJS.DQ,$CLJS.X($CLJS.Fl,gS),xR,$CLJS.X($CLJS.Fl,gS),dR,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,gS))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?a:PO}(),YT],null)]));
qU=VN.l($CLJS.G([$CLJS.bg(dO,SS),$CLJS.WS,$CLJS.bg(dO,PS),TS,$CLJS.bg(dO,MS),NS,$CLJS.bg(dO,QS),YS,$CLJS.Tu,eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(JR,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,$O,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),vQ,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),fP,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(RT)?RT.J:null])):null));return $CLJS.m(a)?a:RP}(),RT],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(yQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BQ,"metabase/mbql/schema.cljc",14,$CLJS.Bk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Bk,$O,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),vQ,$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))),fP,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:BQ}(),ST],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==
typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K($Q,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ZL,"metabase/mbql/schema.cljc",15,$CLJS.Tk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,$CLJS.iS,
$CLJS.X($CLJS.Fl,$CLJS.X(TO,$CLJS.X($CLJS.Jl,WP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:$CLJS.ZL}(),TT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,
GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.oN,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gm,$CLJS.DQ,$CLJS.X($CLJS.Fl,gS),xR,$CLJS.X($CLJS.Fl,gS),dR,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,gS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:$CLJS.oN}(),XT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(DO,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",
14,$CLJS.CK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.CK,$CLJS.DQ,$CLJS.X($CLJS.Fl,gS),xR,$CLJS.X($CLJS.Fl,gS),dR,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,gS))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?a:PO}(),YT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(HQ,new $CLJS.k(null,
1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",13,$CLJS.hm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.hm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(ZT)?ZT.J:null])):null));
return $CLJS.m(a)?a:KP}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(FQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LP,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.jm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:LP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(cS,new $CLJS.k(null,1,
[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",14,$CLJS.tm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.tm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(aU)?aU.J:null])):
null));return $CLJS.m(a)?a:JP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(VR,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SP,"metabase/mbql/schema.cljc",14,$CLJS.sm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sm,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),xR,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:SP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(jja,new $CLJS.k(null,
1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",19,$CLJS.JI,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.JI,$CLJS.DQ,$CLJS.X($CLJS.Fl,aP),$CLJS.qk,$CLJS.X($CLJS.Fl,aP),$CLJS.Yl,$CLJS.X($CLJS.Fl,aP)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:FO}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(mja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BR,"metabase/mbql/schema.cljc",23,$CLJS.uJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.uJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,wR),OO,$CLJS.X($CLJS.Fl,wR),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,ZQ))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:BR}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},
$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",21,$CLJS.mK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.mK,$CLJS.DQ,$CLJS.X($CLJS.Fl,wR),OO,$CLJS.X($CLJS.Fl,wR),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,ZQ))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:SO}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",20,$CLJS.KJ,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,wR),OO,$CLJS.X($CLJS.Fl,wR),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,ZQ))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(lU)?lU.J:null])):null));return $CLJS.m(a)?a:bP}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==
typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(lka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",36,$CLJS.vJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.vJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,wR),OO,$CLJS.X($CLJS.Fl,wR),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,ZQ))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(mU)?mU.J:null])):null));return $CLJS.m(a)?a:mO}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(Pja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),
$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AR,"metabase/mbql/schema.cljc",26,$CLJS.UI,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.UI,Kha,$CLJS.X($CLJS.Fl,aP),qka,$CLJS.X($CLJS.Fl,aP),Yja,$CLJS.X($CLJS.Fl,aP),Oha,$CLJS.X($CLJS.Fl,aP),Rja,$CLJS.X($CLJS.Fl,aP),Jha,$CLJS.X($CLJS.Fl,aP)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:AR}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(wia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",28,$CLJS.KI,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KI,$CLJS.DQ,$CLJS.X($CLJS.Fl,WO)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:mQ}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&
"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",29,$CLJS.$I,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$I,$CLJS.DQ,$CLJS.X($CLJS.Fl,WO)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:SQ}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(fka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",27,$CLJS.xI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xI,$CLJS.DQ,$CLJS.X($CLJS.Fl,WO)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:eP}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&
"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(Jia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",28,$CLJS.nJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,WO)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:JO}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",33,$CLJS.qK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.qK,$CLJS.DQ,$CLJS.X($CLJS.Fl,WO),$CLJS.tC,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tQ,CQ,$CLJS.X(rka,$CLJS.mB,$CLJS.oD,$CLJS.RI))),$CLJS.Wj,$CLJS.X($CLJS.Fl,QP),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,pja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(nU)?nU.J:null])):null));
return $CLJS.m(a)?a:jR}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TR,"metabase/mbql/schema.cljc",27,$CLJS.nI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nI,Sha,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tQ,YO,NR))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(oU)?oU.J:null])):null));return $CLJS.m(a)?a:TR}(),oU],null)]))]));
$CLJS.QT=YN(new $CLJS.ud(function(){return qU},hka,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kia,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(qU)?qU.J:null])));
Ika=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QN($CLJS.QT,"pred"),$CLJS.QN(ZS,"expr")],null)],null);Jka=$CLJS.Pf([RN($CLJS.Pj),ZS]);rU=cO($CLJS.ZI,$CLJS.G(["clauses",Ika,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Jka],null)]));
xT=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(JQ,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DP,"metabase/mbql/schema.cljc",51,$CLJS.lm,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lm,$CLJS.IG,$CLJS.X($CLJS.Fl,MQ),MR,$CLJS.X($CLJS.Fl,MQ),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,MQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:DP}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},
$CLJS.K(fja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",51,$CLJS.xu,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.xu,$CLJS.IG,$CLJS.X($CLJS.Fl,qR),MR,
$CLJS.X($CLJS.Fl,MQ),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,MQ))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:EP}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO?new $CLJS.ud(function(){return nT},$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,cQ,$CLJS.Ak,$CLJS.Xk,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Uu,"metabase/mbql/schema.cljc",$CLJS.PK,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PK,$CLJS.IG,$CLJS.X($CLJS.Fl,qR),MR,$CLJS.X($CLJS.Fl,qR),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,qR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Uu}(),nT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CP,"metabase/mbql/schema.cljc",51,$CLJS.mm,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.mm,$CLJS.IG,$CLJS.X($CLJS.Fl,qR),MR,$CLJS.X($CLJS.Fl,qR),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,qR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:CP}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof aT?new $CLJS.ud(function(){return aT},
$CLJS.K(fR,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",58,$CLJS.QJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QJ,RR,$CLJS.X($CLJS.Fl,pO),QR,$CLJS.X($CLJS.Fl,
pO),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,pO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(aT)?aT.J:null])):null));return $CLJS.m(a)?a:uR}(),aT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof cT?new $CLJS.ud(function(){return cT},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,
$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",56,$CLJS.iD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iD,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(cT)?cT.J:null])):null));return $CLJS.m(a)?a:VQ}(),cT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(fia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aQ,"metabase/mbql/schema.cljc",55,$CLJS.GJ,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GJ,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:aQ}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},$CLJS.K(Yha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),
$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",54,$CLJS.jI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jI,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(qT)?
qT.J:null])):null));return $CLJS.m(a)?a:zO}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(sia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",55,$CLJS.VJ,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VJ,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:TP}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&
"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(mka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",53,$CLJS.tI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.tI,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:rQ}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(Vja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,
$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[ZR,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IR,"metabase/mbql/schema.cljc",69,$CLJS.mI,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mI,$CLJS.IG,$CLJS.X($CLJS.Fl,qR),MR,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:IR}(),tT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(Oia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[ZR,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zP,"metabase/mbql/schema.cljc",68,$CLJS.oJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oJ,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(uT)?uT.J:null])):null));return $CLJS.m(a)?a:zP}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Xja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,
$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[ZR,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",67,$CLJS.ZH,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZH,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(vT)?vT.J:null])):
null));return $CLJS.m(a)?a:VP}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(Rha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[ZR,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",67,$CLJS.RK,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,$CLJS.IG,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:dS}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==
typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K(tO,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Fl,gP),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,zQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:hQ}(),rU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof yT?new $CLJS.ud(function(){return yT},$CLJS.K(Uha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,
cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.hJ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",65,$CLJS.hJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.hJ,lia,$CLJS.X($CLJS.Fl,nS),mia,$CLJS.X($CLJS.Fl,nS),$CLJS.Wj,$CLJS.X($CLJS.Fl,Qja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(yT)?yT.J:null])):null));return $CLJS.m(a)?a:sQ}(),yT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Dja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",71,$CLJS.oK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.oK,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS),$CLJS.Wj,$CLJS.X($CLJS.Fl,Zia),$CLJS.dk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,UR))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(zT)?zT.J:null])):null));return $CLJS.m(a)?a:KQ}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(Gia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MO,"metabase/mbql/schema.cljc",71,$CLJS.EI,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EI,uQ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:MO}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(tia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),
$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",74,$CLJS.SK,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,uQ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(BT)?BT.J:null])):null));return $CLJS.m(a)?a:IQ}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",72,$CLJS.YH,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YH,uQ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:XQ}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==
typeof kO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(dka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",71,$CLJS.cK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.cK,uQ,$CLJS.X($CLJS.Fl,nS),$CLJS.dk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,UR))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:WQ}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(Hha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,
$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",70,$CLJS.aK,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aK,uQ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(ET)?ET.J:
null])):null));return $CLJS.m(a)?a:RQ}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",78,$CLJS.jK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jK,uQ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:NQ}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&
"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",71,$CLJS.wK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.wK,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(GT)?GT.J:null])):null));return $CLJS.m(a)?a:qO}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(Ija,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,
GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hS,"metabase/mbql/schema.cljc",73,$CLJS.gK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gK,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:hS}(),HT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Sia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jQ,"metabase/mbql/schema.cljc",73,$CLJS.DI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DI,$CLJS.BJ,$CLJS.X($CLJS.Fl,nS)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:jQ}(),IT],null)]));
PT=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof bT?new $CLJS.ud(function(){return bT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aR,"metabase/mbql/schema.cljc",59,$CLJS.aI,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.aI,$CLJS.FD,$CLJS.X($CLJS.Fl,wR),$CLJS.SA,$CLJS.X($CLJS.Fl,oia),$CLJS.iD,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,qR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(bT)?bT.J:null])):null));return $CLJS.m(a)?a:aR}(),bT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},
$CLJS.K($ja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",54,$CLJS.BK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BK,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:rR}(),dT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},$CLJS.K(Yia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",55,$CLJS.GK,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GK,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:eR}(),fT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&
"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",
55,$CLJS.YK,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YK,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:xQ}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(Bha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,
$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SR,"metabase/mbql/schema.cljc",57,$CLJS.VK,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.VK,$CLJS.FD,$CLJS.X($CLJS.Fl,wR),Pha,$CLJS.X($CLJS.Fl,$CLJS.PP),$CLJS.AF,$CLJS.X($CLJS.Fl,$CLJS.PP)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(iT)?iT.J:null])):null));return $CLJS.m(a)?a:SR}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},$CLJS.K(Aja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qS,"metabase/mbql/schema.cljc",55,$CLJS.$u,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$u,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:qS}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==
typeof kO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},$CLJS.K(cka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ER,"metabase/mbql/schema.cljc",55,$CLJS.Ru,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Ru,$CLJS.FD,$CLJS.X($CLJS.Fl,wR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:ER}(),gT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(oja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,
GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",56,$CLJS.oI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.oI,RR,$CLJS.X($CLJS.Fl,wR),QR,$CLJS.X($CLJS.Fl,wR),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,wR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(jT)?jT.J:null])):
null));return $CLJS.m(a)?a:rP}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OQ,"null",$CLJS.mfa,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",74,$CLJS.OJ,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.OJ,$CLJS.FD,$CLJS.X($CLJS.Fl,wR),$CLJS.zF,$CLJS.X($CLJS.Fl,$CLJS.PP)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:KO}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&
"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof aT?new $CLJS.ud(function(){return aT},$CLJS.K(fR,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.OQ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",
58,$CLJS.QJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QJ,RR,$CLJS.X($CLJS.Fl,pO),QR,$CLJS.X($CLJS.Fl,pO),ZO,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tk,pO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(aT)?aT.J:null])):null));return $CLJS.m(a)?a:uR}(),aT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K(tO,new $CLJS.k(null,
1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Fl,gP),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,
zQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:hQ}(),rU],null)]));$CLJS.sU=VN.l($CLJS.G([$CLJS.bg(dO,PS),TS,$CLJS.bg(dO,MS),NS,$CLJS.bg(dO,QS),YS,$CLJS.bg(dO,SS),$CLJS.WS,$CLJS.bg(dO,$CLJS.ZI),rU,$CLJS.Tu,$CLJS.KS]));tU=cO($CLJS.kD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.KS],null)]));uU=cO($CLJS.MJ,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.KS],null)]));
vU=cO($CLJS.CJ,$CLJS.G(["field-or-expression",$CLJS.sU]));wU=cO($CLJS.QK,$CLJS.G(["field-or-expression",$CLJS.sU]));xU=cO($CLJS.MI,$CLJS.G(["field-or-expression",$CLJS.sU]));yU=cO($CLJS.sK,$CLJS.G(["field-or-expression",$CLJS.sU]));zU=cO($CLJS.qk,$CLJS.G(["field-or-expression",$CLJS.sU]));AU=cO($CLJS.Yl,$CLJS.G(["field-or-expression",$CLJS.sU]));BU=cO($CLJS.NK,$CLJS.G(["field-or-expression",$CLJS.sU,"pred",$CLJS.QT]));CU=cO($CLJS.eK,$CLJS.G(["pred",$CLJS.QT]));DU=cO($CLJS.YJ,$CLJS.G(["pred",$CLJS.QT]));
EU=cO($CLJS.zK,$CLJS.G(["field-or-expression",$CLJS.sU]));FU=cO($CLJS.QI,$CLJS.G(["field-or-expression",$CLJS.sU]));GU=cO($CLJS.uK,$CLJS.G(["field-or-expression",$CLJS.sU]));HU=cO($CLJS.mJ,$CLJS.G(["field-or-expression",$CLJS.sU,"percentile",VS]));IU=cO($CLJS.$P,$CLJS.G(["metric-id",$CLJS.IN($CLJS.G([$CLJS.MN,$CLJS.JN]))]));
JU=WN($CLJS.bg(dO,PS),TS,eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},$CLJS.K(uja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",60,$CLJS.CJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CJ,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:eQ}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof wU?
new $CLJS.ud(function(){return wU},$CLJS.K(Aia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LR,"metabase/mbql/schema.cljc",64,$CLJS.QK,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,
FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:LR}(),wU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],
[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",65,$CLJS.MI,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MI,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:iQ}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.kK,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sO,"metabase/mbql/schema.cljc",76,$CLJS.zK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zK,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:sO}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(ija,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,
$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",60,$CLJS.sK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sK,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(yU)?yU.J:null])):
null));return $CLJS.m(a)?a:CO}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(Lha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.sl,"metabase/mbql/schema.cljc",60,$CLJS.qk,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qk,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:$CLJS.sl}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&
"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(Gha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bm,"metabase/mbql/schema.cljc",60,$CLJS.Yl,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Yl,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(AU)?AU.J:null])):null));return $CLJS.m(a)?a:$CLJS.bm}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(nia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,
$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",26,$CLJS.$P,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$P,Bia,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tQ,YO,NR))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(IU)?IU.J:null])):null));return $CLJS.m(a)?a:qP}(),IU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==
typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
kP,"metabase/mbql/schema.cljc",62,$CLJS.YJ,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YJ,$CLJS.yl,$CLJS.X($CLJS.Fl,WP)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(DU)?DU.J:null])):null));return $CLJS.m(a)?a:kP}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(Nha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,
$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",68,$CLJS.eK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eK,$CLJS.yl,$CLJS.X($CLJS.Fl,WP)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(CU)?
CU.J:null])):null));return $CLJS.m(a)?a:AO}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(kka,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",66,$CLJS.NK,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.NK,FR,$CLJS.X($CLJS.Fl,yR),$CLJS.yl,$CLJS.X($CLJS.Fl,WP)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(BU)?BU.J:null])):null));return $CLJS.m(a)?a:fQ}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&
"undefined"!==typeof kO&&"undefined"!==typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K(tO,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",61,$CLJS.ZI,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZI,$CLJS.vC,$CLJS.X($CLJS.Fl,gP),$CLJS.Nk,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,zQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:hQ}(),rU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(Lja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,
$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",68,$CLJS.uK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uK,FR,$CLJS.X($CLJS.Fl,yR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(GU)?GU.J:null])):
null));return $CLJS.m(a)?a:KR}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oR,"metabase/mbql/schema.cljc",72,$CLJS.mJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mJ,FR,$CLJS.X($CLJS.Fl,yR),$CLJS.mJ,$CLJS.X($CLJS.Fl,qR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:oR}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&
"undefined"!==typeof kO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",77,$CLJS.QI,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QI,FR,$CLJS.X($CLJS.Fl,
yR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(FU)?FU.J:null])):null));return $CLJS.m(a)?a:rO}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(Iha,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",74,$CLJS.MJ,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,WO))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:XP}(),uU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof tU?new $CLJS.ud(function(){return tU},$CLJS.K(pia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([bQ,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GR,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fI,"null"],null),null),$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.ck,"metabase/mbql/schema.cljc",70,$CLJS.kD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kD,$CLJS.DQ,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.xN,WO))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(tU)?tU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ck}(),tU],null)])));
KU=YN(new $CLJS.ud(function(){return JU},bka,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eka,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(JU)?JU.J:null])));Kka=$CLJS.Pf([RN($CLJS.V),$CLJS.JN,RN($CLJS.TI),$CLJS.JN,$CLJS.FN,$CLJS.AN]);
$CLJS.LU=cO($CLJS.nO,$CLJS.G(["aggregation",KU,"options",Kka]));$CLJS.US=WN($CLJS.bg(dO,$CLJS.nO),$CLJS.LU,KU);MU=cO($CLJS.FJ,$CLJS.G(["field",$CLJS.LS]));NU=cO($CLJS.sJ,$CLJS.G(["field",$CLJS.LS]));
$CLJS.OU=eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof MU?new $CLJS.ud(function(){return MU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IP,"metabase/mbql/schema.cljc",15,$CLJS.FJ,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,lP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(MU)?MU.J:null])):null));return $CLJS.m(a)?a:IP}(),MU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof NU?new $CLJS.ud(function(){return NU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),
$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",16,$CLJS.sJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sJ,$CLJS.DQ,$CLJS.X($CLJS.Fl,lP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(NU)?NU.J:null])):null));return $CLJS.m(a)?a:gR}(),NU],null)]));
TN.l($CLJS.G([$CLJS.lI,$CLJS.PI,$CLJS.cI,$CLJS.mz,$CLJS.GG,uQ]));PU=$CLJS.Pf([RN($CLJS.Gk),$CLJS.JN,$CLJS.V,$CLJS.JN,$CLJS.TI,$CLJS.JN,$CLJS.FN,$CLJS.AN]);Lka=$CLJS.fn.l($CLJS.G([PU,$CLJS.Pf([$CLJS.Wk,$CLJS.QM($CLJS.lI),$CLJS.eI,$CLJS.JN,lja,$CLJS.MN,RN($CLJS.LQ),$CLJS.MN])]));Mka=$CLJS.fn.l($CLJS.G([PU,new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.QM($CLJS.PI),$CLJS.IK,$CLJS.MN],null)]));QU=$CLJS.fn.l($CLJS.G([PU,$CLJS.Pf([RN($CLJS.Pj),$CLJS.AN,RN(jia),$CLJS.CN])]));
Nka=$CLJS.fn.l($CLJS.G([QU,$CLJS.Pf([$CLJS.Wk,$CLJS.QM($CLJS.cI),$CLJS.cI,IS,$CLJS.cJ,YN(new $CLJS.ud(function(){return RU},aka,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(RU)?RU.J:null]))),RN($CLJS.Nk),$CLJS.Pf([$CLJS.FN,
$CLJS.AN])])]));Oka=$CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,4,[uQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.GG,null],null),null));Pka=$CLJS.fn.l($CLJS.G([QU,new $CLJS.k(null,1,[$CLJS.Wk,Oka],null)]));Qka=VN.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.cI)},Nka,function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.lI)},Lka,function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.PI)},Mka,$CLJS.Tu,Pka]));
Rka=$CLJS.WM($CLJS.Pf([$CLJS.JN,Qka]),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");SU=$CLJS.Pf([$CLJS.uO,$CLJS.AN,RN($CLJS.hR),Rka,RN(mP),$CLJS.$N($CLJS.JN),$CLJS.FN,$CLJS.AN]);
UU=WN($CLJS.Mm.h($CLJS.oe,$CLJS.TQ),$CLJS.PN(SU,new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],null)),YN(new $CLJS.ud(function(){return $CLJS.TU},Kia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.TU)?$CLJS.TU.J:null]))));
VU=$CLJS.Pf([$CLJS.V,$CLJS.JN,$CLJS.tJ,$CLJS.KN,kja,$CLJS.JN,RN($CLJS.BP),$CLJS.$N($CLJS.LN),RN($CLJS.dP),$CLJS.$N($CLJS.wha),$CLJS.AN,$CLJS.AN]);$CLJS.WU=/^card__[1-9]\d*$/;XU=$CLJS.IN($CLJS.G([$CLJS.MN,$CLJS.WU]));Ska=$CLJS.R.h(TN,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.HR,null,$CLJS.lO,null,$CLJS.wQ,null,$CLJS.mR,null],null),null));
Tka=$CLJS.WM($CLJS.Sh([$CLJS.FN,RN($CLJS.NP),RN($CLJS.YP),RN($CLJS.vP),RN($CLJS.wO),$CLJS.$R,RN($CLJS.kR),RN($CLJS.iR),RN($CLJS.WK)],[$CLJS.AN,$CLJS.$N(new $CLJS.S(null,1,5,$CLJS.T,[VU],null)),XU,$CLJS.JN,$CLJS.UM($CLJS.IN($CLJS.G([TN.l($CLJS.G([$CLJS.QD,$CLJS.vF])),YN(new $CLJS.ud(function(){return YU},Gja,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,cR,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qja,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m(YU)?YU.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.QT,UU,$CLJS.$N($CLJS.MN),Ska]),$CLJS.Mm.h($CLJS.Nm.h($CLJS.YP,$CLJS.kR),$CLJS.$f($CLJS.Mm.h($CLJS.YP,$CLJS.kR))),"Joins must have either a `source-table` or `source-query`, but not both.");
Uka=$CLJS.WM(fO(new $CLJS.S(null,1,5,$CLJS.T,[Tka],null)),function(a){return gO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.vP,a)))},"All join aliases must be unique.");YU=$CLJS.UM($CLJS.WM(fO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.KS],null)),gO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.TU=$CLJS.WM($CLJS.WM($CLJS.Sh([$CLJS.FN,RN($CLJS.YR),RN($CLJS.NP),RN($CLJS.UO),RN($CLJS.YP),RN($CLJS.IO),RN($CLJS.wO),RN($CLJS.MP),RN($CLJS.FP),RN($CLJS.kR),RN($CLJS.rS),RN(UQ),RN($CLJS.OQ)],[$CLJS.AN,$CLJS.WM(fO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.OU],null)),gO,"distinct"),$CLJS.$N(new $CLJS.S(null,1,5,$CLJS.T,[VU],null)),Uka,XU,$CLJS.QT,YU,fO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.US],null)),fO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.KS],null)),UU,$CLJS.NN,new $CLJS.k(null,2,[UQ,$CLJS.MN,cia,$CLJS.MN],
null),$CLJS.Pf([$CLJS.JN,$CLJS.sU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kR,$CLJS.YP],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.FP);b=$CLJS.M.h(b,$CLJS.wO);return $CLJS.je($CLJS.Ez.h($CLJS.si(a),$CLJS.si(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
ZU=$CLJS.Sh([$CLJS.DR,uQ,jP,cP,OP,lS,yP,$CLJS.kS,$CLJS.mz,xP,vO,vR,aS,$CLJS.EO,WR,iP,$CLJS.UP,wP,$CLJS.Gk,$CLJS.uP,oO,$CLJS.RO,$CLJS.tP,qQ,$CLJS.kk,$CLJS.GG,oP],[new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DR,null,uQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DR,null,uQ,null,$CLJS.kS,null,$CLJS.UP,null,$CLJS.Gk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,
$CLJS.PJ,CR,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[jP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,oS,$CLJS.PJ,CR,HP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DR,null,cP,null,$CLJS.mz,null,$CLJS.Gk,null,oO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,$CLJS.PJ,yO,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[OP,null],null),null)],null),new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,1,[lS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,oS,$CLJS.PJ,Eja,HP,new $CLJS.ni(null,
new $CLJS.k(null,1,[yP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.kS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,oS,HP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DR,null,cP,null,$CLJS.mz,null,$CLJS.Gk,null,oO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,oS,$CLJS.PJ,yO,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[xP,null],null),null)],null),new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,1,[vO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,$CLJS.PJ,CR,HP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.DR,null,lS,null,vO,null,vR,null,wP,null,$CLJS.Gk,null,oO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,$CLJS.PJ,yO,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[aS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.kS,null,$CLJS.EO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,$CLJS.PJ,yO,HP,new $CLJS.ni(null,
new $CLJS.k(null,1,[WR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Ol,$CLJS.PJ,yO,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[iP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.DR,null,uQ,null,$CLJS.kS,null,$CLJS.UP,null,$CLJS.Gk,null],null),null)],null),new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,1,[wP,null],null),null)],null),new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Gk,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.kS,null,$CLJS.uP,null],null),null)],null),new $CLJS.k(null,1,[HP,new $CLJS.ni(null,new $CLJS.k(null,1,[oO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.kS,null,$CLJS.RO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,uQ,HP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.kS,null,$CLJS.tP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,oS,$CLJS.PJ,CR,
HP,new $CLJS.ni(null,new $CLJS.k(null,1,[qQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.kk,HP,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.DR,null,$CLJS.Gk,null,$CLJS.kk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.Ol,HP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.DR,null,lS,null,vO,null,vR,null,wP,null,$CLJS.Gk,null,oO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,oS,$CLJS.PJ,yO,HP,new $CLJS.ni(null,new $CLJS.k(null,1,[oP,null],null),null)],null)]);
Vka=$CLJS.R.h(TN,$CLJS.gi(ZU));RU=$CLJS.R.h(TN,$CLJS.nf($CLJS.vF,$CLJS.gi(ZU)));$U=cO(Tha,$CLJS.G(["tag-name",$CLJS.IN($CLJS.G([$CLJS.JN,new $CLJS.k(null,1,[$CLJS.Gk,$CLJS.JN],null)]))]));aV=cO($CLJS.cI,$CLJS.G(["target",$CLJS.IN($CLJS.G([$CLJS.KS,$U]))]));bV=cO(sP,$CLJS.G(["target",$U]));
Wka=$CLJS.IN($CLJS.G([$CLJS.KS,eO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof aV?new $CLJS.ud(function(){return aV},$CLJS.K(aja,new $CLJS.k(null,1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YQ,"metabase/mbql/schema.cljc",21,$CLJS.cI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cI,$CLJS.$K,$CLJS.X($CLJS.Fl,$CLJS.X($CLJS.tQ,WO,GP))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(aV)?aV.J:null])):null));return $CLJS.m(a)?a:YQ}(),aV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=cQ.g($CLJS.fe("undefined"!==typeof $CLJS.iO&&"undefined"!==typeof jO&&"undefined"!==typeof kO&&"undefined"!==typeof bV?new $CLJS.ud(function(){return bV},$CLJS.K(rja,new $CLJS.k(null,
1,[$CLJS.Ql,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,cQ,$CLJS.Ak,$CLJS.Xk,$CLJS.um,GO,$CLJS.Zj,$CLJS.W,$CLJS.dm],[$CLJS.K(pR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",20,sP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[sP,$CLJS.$K,$CLJS.X($CLJS.Fl,GP)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(bV)?bV.J:null])):null));return $CLJS.m(a)?a:pS}(),
bV],null)]))]));Xka=$CLJS.Pf([$CLJS.Wk,Vka,RN($CLJS.Gk),$CLJS.JN,RN($CLJS.$K),Wka,RN($CLJS.ql),$CLJS.AN,RN($CLJS.V),$CLJS.JN,RN($ha),$CLJS.JN,RN($CLJS.Pj),$CLJS.AN,$CLJS.FN,$CLJS.AN]);Yka=new $CLJS.S(null,1,5,$CLJS.T,[Xka],null);Zka=$CLJS.Pf([RN(ika),$CLJS.JN,$CLJS.FN,$CLJS.AN]);$ka=$CLJS.WM($CLJS.Pf([RN(gQ),$CLJS.NN,RN(BO),$CLJS.NN,$CLJS.FN,$CLJS.AN]),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,gQ);b=$CLJS.M.h(b,BO);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
ala=$CLJS.Pf([RN(Nja),$CLJS.CN,RN(Vha),$CLJS.CN,RN(Cha),$CLJS.CN,RN(bia),$CLJS.CN,RN(Fja),$CLJS.$N($CLJS.CN),RN(Fha),$CLJS.$N($CLJS.CN),RN(Uja),$CLJS.$N($CLJS.CN),$CLJS.FN,$CLJS.AN]);bla=TN.l($CLJS.G([nja,mP,bja,sja,Nia,Qha,Wha,iia,yia,Bja,ska,Cia,uia,gka]));
cla=$CLJS.Sh([$CLJS.FN,RN(ela),RN(Eia),RN($CLJS.nP),RN(Kja),RN(Lia),RN(Dha),RN(jka),RN($CLJS.IK),RN($CLJS.LO)],[$CLJS.AN,$CLJS.$N($CLJS.JN),$CLJS.$N($CLJS.MN),$CLJS.$N(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pf([$CLJS.AN,$CLJS.AN])],null)),$CLJS.$N($CLJS.MN),$CLJS.$N($CLJS.MN),$CLJS.$N($CLJS.AN),$CLJS.$N($CLJS.Pf([$CLJS.AN,$CLJS.AN])),$CLJS.$N($CLJS.MN),$CLJS.$N(bla)]);dla=$CLJS.IN($CLJS.G([$CLJS.QM(-1337),$CLJS.MN]));
$CLJS.cV=$CLJS.WM($CLJS.WM($CLJS.WM($CLJS.Sh([$CLJS.FN,RN($CLJS.TQ),RN($CLJS.lR),RN($CLJS.ZD),RN($ia),RN(Pia),$CLJS.Wk,RN($CLJS.uO),$CLJS.LQ,RN($CLJS.NO)],[$CLJS.AN,SU,$CLJS.$N(Zka),$CLJS.$N(cla),$CLJS.$N($ka),$CLJS.$N(ala),TN.l($CLJS.G([$CLJS.uO,$CLJS.TQ])),$CLJS.TU,dla,Yka]),$CLJS.Mm.h($CLJS.Nm.h($CLJS.TQ,$CLJS.uO),$CLJS.$f($CLJS.Mm.h($CLJS.TQ,$CLJS.uO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.TQ);var c=$CLJS.M.h(b,$CLJS.uO);
b=$CLJS.M.h(b,$CLJS.Wk);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.$f($CLJS.NP),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.aO(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.zj($CLJS.UL("Value does not match schema: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.Wk,$CLJS.eha,$CLJS.cm,a,$CLJS.ql,c,$CLJS.Wu,d],null));return c}})($CLJS.cV);