import { t } from "ttag";
import { updateIn } from "icepick";

import {
  PLUGIN_IS_PASSWORD_USER,
  PLUGIN_AUTH_PROVIDERS,
  PLUGIN_ADMIN_SETTINGS_UPDATES,
} from "metabase/plugins";

import MetabaseSettings from "metabase/lib/settings";

import SettingsWebApiForm from "metabase/admin/settings/components/SettingsWebApiForm";
import WebApiCard from "metabase/admin/settings/auth/containers/WebApiCard";

PLUGIN_IS_PASSWORD_USER.push(user => user.sso_source !== "web-api");

PLUGIN_AUTH_PROVIDERS.push(providers => {
  const webApiProvider = {
    name: "web-api",
    // circular dependencies
    Button: require("metabase/auth/components/WebApiButton").WebApiButton,
  };

  return MetabaseSettings.isWebApiEnabled() ? [webApiProvider] : providers;
});

PLUGIN_ADMIN_SETTINGS_UPDATES.push(
  sections =>
    updateIn(sections, ["authentication", "settings"], settings => [
      ...settings,
      {
        key: "web-api-enabled",
        description: null,
        noHeader: true,
        widget: WebApiCard,
      },
    ]),
  sections => ({
    ...sections,
    "authentication/web-api": {
      sidebar: false,
      component: SettingsWebApiForm,
      settings: [
        {
          key: "web-api-enabled",
          display_name: t`Web API Authentication`,
          description: null,
          type: "boolean",
        },
        {
          key: "web-api-key",
          display_name: t`API Key`,
          type: "string",
          required: true,
          autoFocus: true,
        },
        {
          key: "web-api-url",
          display_name: t`API Url`,
          placeholder: "http://web-api.the-domain.com/signon",
          type: "string",
          required: true,
        },
        {
          key: "web-api-url-local",
          display_name: t`Fallback Url`,
          placeholder: "http://localhost:5050/signon",
          type: "string",
        },
      ],
    },
  }),
);
