var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var yqa,c3,zqa,g3,h3,y3,Bqa,Cqa,C3,r3,E3,Eqa,t3;yqa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};c3=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ie($CLJS.Bfa,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
zqa=function(a){var b=$CLJS.Qf(new $CLJS.k(null,1,[$CLJS.EG,!0],null)),c=$CLJS.M.h(b,$CLJS.Afa),d=$CLJS.M.h(b,$CLJS.EG);return $CLJS.A(a)?(b=$CLJS.bv.g(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=c3(x,$CLJS.Gb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(c3(u,
$CLJS.Gb(d)),g($CLJS.zd(l)))}return null}},null,null)}(yqa($CLJS.jG(a)))}()),$CLJS.m(c)?$CLJS.bv.g($CLJS.jg(c,b)):b):null};$CLJS.d3=function(a,b){var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.zj($CLJS.UH("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[$CLJS.Ooa,$CLJS.E(a)],null));return c};$CLJS.e3=function(a,b){a=$CLJS.c0(a);a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.gZ);return $CLJS.M.h($CLJS.Mg(a),$CLJS.d3(a,b))};
$CLJS.f3=function(a,b,c,d){a=$CLJS.c0(a);a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.gZ);b=$CLJS.d3(e,b);c=$CLJS.R.R($CLJS.Qm,$CLJS.Mg(e),b,c,d);return $CLJS.U.j(a,$CLJS.gZ,c)};g3=function(a,b){if(null!=a&&null!=a.qh)a=a.qh(a,b);else{var c=g3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=g3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.table",a);}return a};
h3=function(a,b){if(null!=a&&null!=a.jh)a=a.jh(a,b);else{var c=h3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=h3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.card",a);}return a};$CLJS.i3=function(a){return $CLJS.O2(a)?a:$CLJS.mZ.g(a)};$CLJS.j3=function(a,b){return g3($CLJS.i3(a),b)};$CLJS.k3=function(a,b){return h3($CLJS.i3(a),b)};$CLJS.m3=function(a,b,c){return $CLJS.l3.j(a,b,c)};
y3=function(a,b,c){var d=$CLJS.m3(a,b,c);return $CLJS.fn.l($CLJS.G([$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.TI,$CLJS.Kl],null)),function(){var e=$CLJS.n3.D(a,b,c,$CLJS.o3);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.p3,e],null):null}(),function(){var e=$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null}(),function(){var e=$CLJS.j0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.k0,$CLJS.q3.j(a,b,$CLJS.Ae(e)?$CLJS.j3(a,e):"string"===typeof e?
$CLJS.k3(a,$CLJS.d0(e)):null)],null):null}(),function(){var e=$CLJS.e0.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[r3,$CLJS.F.h(e,$CLJS.Q2),$CLJS.s3,$CLJS.F.h(e,$CLJS.U2),t3,$CLJS.F.h(e,$CLJS.S2),$CLJS.u3,$CLJS.F.h(e,$CLJS.T2)],null):null}(),function(){var e=$CLJS.W2.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.v3,e],null):null}(),$CLJS.li(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.w3,$CLJS.x3],null))]))};$CLJS.z3=new $CLJS.N(null,"metadata-fn","metadata-fn",915781389);
$CLJS.A3=new $CLJS.N(null,"direction","direction",-633359395);$CLJS.u3=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.w3=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.B3=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.v3=new $CLJS.N(null,"selected","selected",574897764);Bqa=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);
Cqa=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);C3=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.D3=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);r3=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);E3=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);
$CLJS.F3=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.s3=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.G3=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.x3=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);Eqa=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.p3=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);$CLJS.o3=new $CLJS.N(null,"long","long",-171452093);
$CLJS.H3=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.I3=new $CLJS.N("lib","source-uuid","lib/source-uuid",1828897581);$CLJS.J3=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);t3=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.K3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.L3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.n3=function n3(a){switch(arguments.length){case 2:return n3.h(arguments[0],arguments[1]);case 3:return n3.j(arguments[0],arguments[1],arguments[2]);case 4:return n3.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.metadata.calculation.display_name",$CLJS.n3);$CLJS.n3.h=function(a,b){return $CLJS.n3.j(a,-1,b)};$CLJS.n3.j=function(a,b,c){return $CLJS.n3.D(a,b,c,$CLJS.Pj)};
$CLJS.n3.D=function(a,b,c,d){var e=$CLJS.TI.g($CLJS.EH(c));if($CLJS.m(e))return e;try{return $CLJS.K3.D(a,b,c,d)}catch(f){if(f instanceof Error)throw b=f,$CLJS.yj($CLJS.UH("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.SZ(b)])),new $CLJS.k(null,2,[$CLJS.uO,a,$CLJS.IG,c],null),b);throw f;}};$CLJS.n3.v=4;
$CLJS.M3=function M3(a){switch(arguments.length){case 2:return M3.h(arguments[0],arguments[1]);case 3:return M3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.M3.h=function(a,b){return $CLJS.M3.j(a,-1,b)};
$CLJS.M3.j=function(a,b,c){var d=$CLJS.V.g($CLJS.EH(c));if($CLJS.m(d))return d;try{return $CLJS.L3.j(a,b,c)}catch(e){if(e instanceof Error)throw d=e,$CLJS.yj($CLJS.UH("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.IG,c,$CLJS.uO,a,$CLJS.h0,b],null),d);throw e;}};$CLJS.M3.v=3;
$CLJS.K3.o(null,$CLJS.Pj,function(a,b,c){a=$CLJS.XF($CLJS.XD);$CLJS.m($CLJS.dG("metabase.lib.metadata.calculation",a))&&$CLJS.eG("metabase.lib.metadata.calculation",a,$CLJS.W_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),Cqa,$CLJS.CH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.ui($CLJS.C(c)):$CLJS.aj.l($CLJS.G([c]))});$CLJS.L3.o(null,$CLJS.Pj,function(a,b,c){return zqa($CLJS.Nu($CLJS.n3.j(a,b,c),/[\(\)]/,""))});
$CLJS.N3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.O3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.P3=function P3(a){switch(arguments.length){case 2:return P3.h(arguments[0],arguments[1]);case 3:return P3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.P3.h=function(a,b){return $CLJS.P3.j(a,-1,b)};
$CLJS.P3.j=function(a,b,c){var d=$CLJS.EH(c);d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.PQ),f=$CLJS.lk.g(d);if($CLJS.m(f))return f;e=$CLJS.m($CLJS.m(e)?$CLJS.Ie($CLJS.VV,e):e)?$CLJS.il:null;if($CLJS.m(e))return e;d=$CLJS.CG.g(d);if($CLJS.m(d))return d;a=$CLJS.O3.j(a,b,c);return $CLJS.OH(a,$CLJS.nl)?a:$CLJS.nl};$CLJS.P3.v=3;$CLJS.O3.o(null,$CLJS.Pj,function(a,b,c){return $CLJS.BL(c)});
$CLJS.O3.o(null,$CLJS.NL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.P3.j(a,b,c)});$CLJS.O3.o(null,$CLJS.GX,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.BL(c):$CLJS.P3.j(a,b,d)});
$CLJS.l3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.l3.o(null,$CLJS.Pj,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.rl,$CLJS.hW,$CLJS.CG,$CLJS.P3.j(a,b,c),$CLJS.V,$CLJS.M3.j(a,b,c),$CLJS.TI,$CLJS.n3.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.UH("Error calculating metadata {0}",$CLJS.G([$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.h0,b,$CLJS.IG,c],null),d);}throw e;}});
$CLJS.Q3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.jL(C3,new $CLJS.S(null,19,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.TI,$CLJS.Ol],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.p3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Ol],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.k0,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,C3],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[r3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.s3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[t3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.u3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[E3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Eqa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Bqa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Ol],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Ol],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Ol],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.H3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Ol],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.G3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.v3,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.A3,
new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,$CLJS.FJ,$CLJS.sJ],null)],null)],null));$CLJS.q3=function q3(a){switch(arguments.length){case 2:return q3.h(arguments[0],arguments[1]);case 3:return q3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.q3.h=function(a,b){return $CLJS.q3.j(a,-1,b)};
$CLJS.q3.j=function(a,b,c){try{return $CLJS.Q3.j(a,b,c)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.UH("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.CH(c),$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.h0,b,$CLJS.IG,c],null),d);}throw e;}};$CLJS.q3.v=3;$CLJS.Q3.o(null,$CLJS.Pj,function(a,b,c){return y3(a,b,c)});
$CLJS.Q3.o(null,$CLJS.g0,function(a,b,c){return $CLJS.fn.l($CLJS.G([y3(a,b,c),new $CLJS.k(null,1,[E3,$CLJS.F.h($CLJS.YP.g($CLJS.C($CLJS.gZ.g(a))),$CLJS.Gk.g(c))],null)]))});
$CLJS.R3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.CH(l)},$CLJS.Pj,e,a,b,c,d)}();$CLJS.R3.o(null,$CLJS.FG,function(){return $CLJS.Lg});module.exports={display_name:$CLJS.n3};