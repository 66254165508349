var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var $4,a5,c5,d5;$CLJS.Y4=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.Z4=function(a,b){a=$CLJS.Y4(a);b=$CLJS.m(b)?b:$CLJS.wz;return 0===a?$CLJS.F.h(b,$CLJS.wz)?$CLJS.VH("Today"):$CLJS.UH("This {0}",$CLJS.G([$CLJS.q4.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.wz)?$CLJS.VH("Tomorrow"):$CLJS.UH("Next {0}",$CLJS.G([$CLJS.q4.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.wz)?$CLJS.VH("Yesterday"):$CLJS.UH("Previous {0}",$CLJS.G([$CLJS.q4.g(b)])):0>a?$CLJS.UH("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.q4.h(Math.abs(a),b)])):0<a?$CLJS.UH("Next {0} {1}",$CLJS.G([a,$CLJS.q4.h(a,
b)])):null};$4=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);a5=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.b5=new $CLJS.N(null,"operators","operators",-2064102509);c5=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);d5=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var e5=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),f5=null,g5=0,h5=0;;)if(h5<g5){var $qa=f5.$(null,h5);$CLJS.lV($qa,c5);h5+=1}else{var i5=$CLJS.A(e5);if(i5){var j5=i5;if($CLJS.re(j5)){var k5=$CLJS.$c(j5),ara=$CLJS.ad(j5),bra=k5,cra=$CLJS.E(k5);e5=ara;f5=bra;g5=cra}else{var dra=$CLJS.C(j5);$CLJS.lV(dra,c5);e5=$CLJS.D(j5);f5=null;g5=0}h5=0}else break}
for(var l5=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.CK],null)),m5=null,n5=0,o5=0;;)if(o5<n5){var era=m5.$(null,o5);$CLJS.lV(era,a5);o5+=1}else{var p5=$CLJS.A(l5);if(p5){var q5=p5;if($CLJS.re(q5)){var r5=$CLJS.$c(q5),fra=$CLJS.ad(q5),gra=r5,hra=$CLJS.E(r5);l5=fra;m5=gra;n5=hra}else{var ira=$CLJS.C(q5);$CLJS.lV(ira,a5);l5=$CLJS.D(q5);m5=null;n5=0}o5=0}else break}
for(var s5=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.hm,$CLJS.tm,$CLJS.jm,$CLJS.sm,$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ],null)),t5=null,u5=0,v5=0;;)if(v5<u5){var jra=t5.$(null,v5);$CLJS.lV(jra,d5);v5+=1}else{var w5=$CLJS.A(s5);if(w5){var x5=w5;if($CLJS.re(x5)){var y5=$CLJS.$c(x5),kra=$CLJS.ad(x5),lra=y5,mra=$CLJS.E(y5);s5=kra;t5=lra;u5=mra}else{var nra=$CLJS.C(x5);$CLJS.lV(nra,d5);s5=$CLJS.D(x5);t5=null;u5=0}v5=0}else break}
for(var z5=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xI,$CLJS.nJ,$CLJS.KI,$CLJS.$I,$CLJS.Tk],null)),A5=null,B5=0,C5=0;;)if(C5<B5){var ora=A5.$(null,C5);$CLJS.lV(ora,$4);C5+=1}else{var D5=$CLJS.A(z5);if(D5){var E5=D5;if($CLJS.re(E5)){var F5=$CLJS.$c(E5),pra=$CLJS.ad(E5),qra=F5,rra=$CLJS.E(F5);z5=pra;A5=qra;B5=rra}else{var tra=$CLJS.C(E5);$CLJS.lV(tra,$4);z5=$CLJS.D(E5);A5=null;B5=0}C5=0}else break}
$CLJS.N3.o(null,$CLJS.MZ,function(a,b){var c=$CLJS.Rf($CLJS.MZ.g($CLJS.e3(a,b)));return $CLJS.m(c)?$CLJS.UH("Filtered by {0}",$CLJS.G([$CLJS.O4($CLJS.VH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.n3.D(a,b,v,$CLJS.o3);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);
return $CLJS.nf($CLJS.n3.D(a,b,q,$CLJS.o3),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.K3.o(null,c5,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.O4(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.VH("and");case "or":return $CLJS.VH("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<
v){var B=$CLJS.be(u,y);B=$CLJS.n3.D(a,b,B,d);x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.n3.D(a,b,x,d),n($CLJS.zd(q)))}return null}},null,null)}(f)}())});
$CLJS.K3.o(null,a5,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.rg.h(function(g){return $CLJS.n3.D(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.UH("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.UH("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.O4($CLJS.VH("or"),
$CLJS.zd(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.UH("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.UH("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.K3.o(null,d5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.n3.D(a,b,f,d);a=$CLJS.n3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.UH("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.UH("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.UH("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.UH("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.UH("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.UH("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.UH("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.UH("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.K3.o(null,$CLJS.JI,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.UH("{0} is between {1} and {2}",$CLJS.G([$CLJS.n3.D(a,b,e,d),$CLJS.n3.D(a,b,f,d),$CLJS.n3.D(a,b,c,d)]))});
$CLJS.K3.o(null,$CLJS.UI,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.n3.D(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.JI,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.JI,e,g,n,c],null)],null),d)});
$CLJS.K3.o(null,$4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.n3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.UH("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.UH("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.UH("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.UH("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.UH("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.K3.o(null,$CLJS.qK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.UH("{0} is within {1}",$CLJS.G([$CLJS.n3.D(a,b,e,d),$CLJS.Z4(f,c).toLowerCase()]))});$CLJS.K3.o(null,$CLJS.hY,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.TI)});
$CLJS.Q3.o(null,$CLJS.hY,function(a,b,c){b=$CLJS.Qf(c);a=$CLJS.M.h(b,$CLJS.AV);b=$CLJS.M.h(b,$CLJS.TI);return new $CLJS.k(null,2,[$CLJS.H3,$CLJS.iG(a),$CLJS.TI,b],null)});