import { useSelector } from "metabase/lib/redux";
import { getWebApiEnabled } from "metabase/auth/selectors";

export const useWebApiEnabledIframe = () => {
  const isWebApiEnabled = useSelector(getWebApiEnabled);
  if (!isWebApiEnabled) {
    return false;
  }
  // https://stackoverflow.com/a/326076
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
