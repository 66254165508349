import styled from "@emotion/styled";

export const Span = styled.span`
  padding-left: 0.5rem;
`;

export const SpanLink = styled.span`
  padding-left: 0.5rem;
  cursor: pointer;
`;
