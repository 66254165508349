import { t } from "ttag";
import { connect } from "react-redux";
import { getSetting } from "metabase/selectors/settings";
import { updateSettings } from "metabase/admin/settings/settings";
import { Dispatch, State } from "metabase-types/store";
import AuthCard from "../../components/AuthCard";
import { WEB_API_SCHEMA } from "../../constants";

const mapStateToProps = (state: State) => ({
  type: "web-api",
  name: t`Web API`,
  description: t`Allows users to log in to Metabase provided they have an active session with any web application provided the Web API component has been enabled.`,
  isConfigured: getSetting(state, "web-api-enabled"),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDeactivate: () => dispatch(updateSettings(WEB_API_SCHEMA.getDefault())),
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default connect(mapStateToProps, mapDispatchToProps)(AuthCard);
