import React from "react";
import _ from "underscore";
import { t } from "ttag";

import { getAccentColors } from "metabase/lib/colors/groups";
import ColorSelector from "metabase/core/components/ColorSelector";
import Input from "metabase/core/components/Input";
import { Icon } from "metabase/core/components/Icon";

import { Span, SpanLink } from "./ChartSettingGoals.styled";

const getNewOption = () => {
  const colors = getAccentColors();
  return {
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
    label: "",
    value: "",
  };
};

const cleanValue = valueStr => {
  let value;

  try {
    value = JSON.parse(valueStr);
    value = Array.isArray(value) ? value : [];
  } catch (e) {
    value = [];
  }

  if (0 === value.length) {
    value.push(getNewOption());
  }

  return value;
};

interface ChartSettingGoalsProps {
  value: string;
  onChange: (value: string) => void;
  id?: string;
}

export const ChartSettingGoals = ({
  value,
  onChange,
  ...props
}: ChartSettingGoalsProps) => {
  const [data, setData] = React.useState(cleanValue(value));

  const mapValue = (index, o) => {
    return data.map((v, i) => {
      return index !== i
        ? v
        : {
            label: o.label || v.label,
            color: o.color || v.color,
            value: o.value ? o.value.replace(/[^\d.-]/g, "") : v.value,
          };
    });
  };

  const onUpdate = vals => {
    setData(vals);
    onChange?.(JSON.stringify(vals));
  };

  const onInputChange = (index, options) => {
    setData(mapValue(index, options));
  };

  const onInputBlur = (index, options) => {
    onUpdate(mapValue(index, options));
  };

  const inputProps = _.omit(props, "id", "onBlur", "onBlurChange", "onChange");

  return (
    <div data-testid={props.id}>
      {data.map((v, index) => (
        <div key={index} className="mt2 flex flex-row align-center">
          <ColorSelector
            pillSize={14}
            value={v.color}
            colors={getAccentColors()}
            onChange={color => onInputBlur(index, { color })}
          />
          &nbsp;
          <Input
            {...inputProps}
            fullWidth
            value={v.label}
            placeholder={t`Label`}
            onChange={e => onInputChange(index, { label: e.target.value })}
            onBlur={e => onInputBlur(index, { label: e.target.value })}
          />
          &nbsp;
          <Input
            {...inputProps}
            fullWidth
            value={v.value}
            placeholder={t`Value`}
            onChange={e => onInputChange(index, { value: e.target.value })}
            onBlur={e => onInputBlur(index, { value: e.target.value })}
          />
          {0 === index && (
            <Span>
              <svg width={12} height={12} />
            </Span>
          )}
          {0 < index && (
            <SpanLink
              className="text-medium text-error-hover"
              onClick={() => onUpdate(data.filter((v, i) => index !== i))}
            >
              <Icon name="close" size={12} />
            </SpanLink>
          )}
        </div>
      ))}
      <div className="flex flex-row">
        <div className="mt2 ml-auto">
          <a
            className="text-medium text-bold align-center px1"
            onMouseDown={e => e.preventDefault()}
            onClick={() => onUpdate([...data, getNewOption()])}
          >
            {t`Add Goal`}
          </a>
        </div>
      </div>
    </div>
  );
};
