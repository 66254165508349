var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var mX,nX,zma,Ama,Bma,pX,qX,rX,sX,uX,Vma,wX;mX=function(a){switch(arguments.length){case 2:return $CLJS.nV(arguments[0],arguments[1]);case 3:return $CLJS.mV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};nX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};zma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Ama=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.oX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Bma=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);pX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
qX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);rX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);sX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.tX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
uX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Vma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.vX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);wX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.xX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.AL.o(null,$CLJS.FG,function(){return $CLJS.nl});$CLJS.jL(wX,$CLJS.kk);$CLJS.AL.o(null,$CLJS.MG,function(){return $CLJS.Al});$CLJS.jL(wX,$CLJS.kk);$CLJS.jL(Vma,$CLJS.Rt);$CLJS.AL.o(null,$CLJS.uG,function(){return $CLJS.il});$CLJS.jL(Ama,$CLJS.Qt);$CLJS.AL.o(null,$CLJS.sG,function(){return $CLJS.KK});$CLJS.jL(Bma,$CLJS.Ol);
var Cma=[":\\d{2}",nX($CLJS.G(["\\.\\d{1,6}"]))].join(""),yX=["\\d{2}:\\d{2}",nX($CLJS.G([Cma]))].join(""),zX=["\\d{4}-\\d{2}-\\d{2}T",yX].join(""),AX=["(?:Z|(?:[+-]",yX,"))"].join(""),Dma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Ema=$CLJS.Fi(["^",yX,"$"].join("")),Fma=$CLJS.Fi(["^",yX,AX,"$"].join("")),Gma=$CLJS.Fi(["^",zX,"$"].join("")),Hma=$CLJS.Fi(["^",zX,AX,"$"].join(""));$CLJS.jL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"date string literal"],null),Dma],null));
$CLJS.jL(qX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"local time string literal"],null),Ema],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"offset time string literal"],null),Fma],null)],null));
$CLJS.jL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"local date time string literal"],null),Gma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"offset date time string literal"],null),Hma],null)],null));
$CLJS.AL.o(null,$CLJS.tG,function(a){return $CLJS.m($CLJS.nV?$CLJS.nV(sX,a):mX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Sl,null,$CLJS.SJ,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(rX,a):mX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.Sl,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(qX,a):mX.call(null,qX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.XK,null,$CLJS.Sl,null],null),null):$CLJS.Sl});$CLJS.jL($CLJS.tX,rX);$CLJS.jL(pX,qX);
$CLJS.jL($CLJS.xX,sX);$CLJS.jL(zma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Bk,$CLJS.tX,pX,$CLJS.xX],null));$CLJS.BX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.jL($CLJS.vX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"year-month string literal"],null),$CLJS.BX],null));$CLJS.CX=$CLJS.Fi("^\\d{4}$");$CLJS.jL($CLJS.oX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.dv,"year string literal"],null),$CLJS.CX],null));
$CLJS.jL(uX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lk,$CLJS.xL],null)],null)],null));$CLJS.sV($CLJS.ql,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.dv,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.ql],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,uX],null),$CLJS.Lb],null));