import React from "react";
import { t } from "ttag";
import { useDispatch, useSelector } from "metabase/lib/redux";
import { AuthButton } from "../AuthButton";
import { getWebApiUrlLogin } from "../../selectors";
import { loginWebAPI } from "../../actions";

export interface WebApiButtonProps {
  redirectUrl?: string;
  isCard?: boolean;
}

export const WebApiButton = ({ redirectUrl, isCard }: WebApiButtonProps) => {
  const webApiUrlLogin = useSelector(getWebApiUrlLogin);
  const dispatch = useDispatch();

  const query = new URLSearchParams(window.location.search);
  const keys = ["session_id", "key"];

  const hasData = () => {
    let i = 0;
    keys.forEach(k => {
      i += query.has(k) ? 1 : 0;
    });
    return keys.length === i;
  };

  const loginDispatch = async () => {
    const data = { redirectUrl };
    keys.forEach(k => {
      data[snakeToCamel(k)] = query.get(k);
    });
    await dispatch(loginWebAPI(data)).unwrap();
  };

  // https://stackoverflow.com/a/73757919/3003786
  const snakeToCamel = s => s.replace(/(?!^)_(.)/g, (_, c) => c.toUpperCase());

  React.useEffect(() => {
    const key = "MetabaseAPIProviderStorage";
    if (hasData()) {
      const redirect = localStorage.getItem(key);
      localStorage.removeItem(key);
      loginDispatch();
      if ("__clientAuth__" === redirect) {
        setTimeout(() => window.close(), 100);
      }
    } else {
      localStorage.setItem(
        key,
        query.has("client") ? "__clientAuth__" : redirectUrl,
      );
      window.location = webApiUrlLogin;
    }
  });

  return (
    <AuthButton isCard={isCard}>
      {hasData() ? (
        <>{t`Activating your session.`}</>
      ) : (
        <>{t`Please wait for the authentication process to complete.`}</>
      )}
    </AuthButton>
  );
};
