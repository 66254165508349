var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R5,S5,T5,U5;R5=function(a,b,c){var d=$CLJS.e3(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.MP);d=$CLJS.uW($CLJS.Lm.j($CLJS.pi([c]),$CLJS.HG,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.UH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Ut,c,$CLJS.uO,a,$CLJS.h0,b],null));return d};S5=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
T5=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);U5=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.Gra=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.N3.o(null,$CLJS.MP,function(a,b){var c=$CLJS.Rf($CLJS.MP.g($CLJS.e3(a,b)));return $CLJS.m(c)?$CLJS.O4($CLJS.VH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.n3.D(a,b,v,$CLJS.o3);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.n3.D(a,
b,q,$CLJS.o3),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.l3.o(null,$CLJS.MP,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.CG);e=$CLJS.M.h(e,$CLJS.lk);c=$CLJS.J(c,2,null);c=R5(a,b,c);return $CLJS.fn.l($CLJS.G([$CLJS.m3(a,b,c),new $CLJS.k(null,2,[$CLJS.e0,$CLJS.a3,$CLJS.I3,$CLJS.HG.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.CG,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null]))});
$CLJS.K3.o(null,$CLJS.MP,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.n3.D(a,b,R5(a,b,c),d)});$CLJS.lV(T5,U5);
for(var V5=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kD,$CLJS.MJ],null)),W5=null,X5=0,Y5=0;;)if(Y5<X5){var Hra=W5.$(null,Y5);$CLJS.lV(Hra,T5);Y5+=1}else{var Z5=$CLJS.A(V5);if(Z5){var $5=Z5;if($CLJS.re($5)){var a6=$CLJS.$c($5),Ira=$CLJS.ad($5),Jra=a6,Kra=$CLJS.E(a6);V5=Ira;W5=Jra;X5=Kra}else{var Lra=$CLJS.C($5);$CLJS.lV(Lra,T5);V5=$CLJS.D($5);W5=null;X5=0}Y5=0}else break}
$CLJS.K3.o(null,T5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.n3.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.UH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.UH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.VH("Count");case "cum-count":return $CLJS.VH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.L3.o(null,T5,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.l3.o(null,T5,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.tj($CLJS.l3,U5);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Kl,$CLJS.SI)});$CLJS.K3.o(null,$CLJS.ZI,function(){return $CLJS.VH("Case")});
$CLJS.L3.o(null,$CLJS.ZI,function(){return"case"});$CLJS.lV(S5,U5);for(var b6=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.CJ,$CLJS.QK,$CLJS.MI,$CLJS.Yl,$CLJS.uK,$CLJS.qk,$CLJS.zK,$CLJS.sK,$CLJS.QI],null)),c6=null,d6=0,e6=0;;)if(e6<d6){var Mra=c6.$(null,e6);$CLJS.lV(Mra,S5);e6+=1}else{var f6=$CLJS.A(b6);if(f6){var g6=f6;if($CLJS.re(g6)){var h6=$CLJS.$c(g6),Nra=$CLJS.ad(g6),Ora=h6,Pra=$CLJS.E(h6);b6=Nra;c6=Ora;d6=Pra}else{var Qra=$CLJS.C(g6);$CLJS.lV(Qra,S5);b6=$CLJS.D(g6);c6=null;d6=0}e6=0}else break}
$CLJS.L3.o(null,S5,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.K3.o(null,S5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.n3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.UH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.UH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.UH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.UH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.UH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.UH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.UH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.UH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.UH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.K3.o(null,$CLJS.mJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.UH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.n3.D(a,b,e,d)]))});$CLJS.L3.o(null,$CLJS.mJ,function(){return"percentile"});
$CLJS.lV($CLJS.mJ,U5);$CLJS.K3.o(null,$CLJS.NK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.UH("Sum of {0} matching condition",$CLJS.G([$CLJS.n3.D(a,b,e,d)]))});$CLJS.L3.o(null,$CLJS.NK,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.M3.j(a,b,d))].join("")});$CLJS.lV($CLJS.NK,U5);$CLJS.K3.o(null,$CLJS.YJ,function(){return $CLJS.VH("Share of rows matching condition")});
$CLJS.L3.o(null,$CLJS.YJ,function(){return"share"});$CLJS.lV($CLJS.YJ,U5);$CLJS.K3.o(null,$CLJS.eK,function(){return $CLJS.VH("Count of rows matching condition")});$CLJS.L3.o(null,$CLJS.eK,function(){return"count-where"});$CLJS.lV($CLJS.eK,U5);
$CLJS.l3.o(null,U5,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.fn.l;d=$CLJS.m(d)?$CLJS.li($CLJS.m3(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lR],null)):null;var f=$CLJS.tj($CLJS.l3,$CLJS.Pj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.fn,$CLJS.G([d,a]))});$CLJS.bW.o(null,$CLJS.MP,function(a){return a});$CLJS.K3.o(null,$CLJS.mW,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.pW);return $CLJS.TI.g(a.A?a.A():a.call(null))});
$CLJS.Q3.o(null,$CLJS.mW,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.AV);b=$CLJS.M.h(d,$CLJS.pW);c=$CLJS.M.h(d,$CLJS.nW);d=$CLJS.M.h(d,$CLJS.W2);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.H3,$CLJS.iG(a),$CLJS.G([$CLJS.G3,c]));return null!=d?$CLJS.U.j(a,$CLJS.v3,d):a});