var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var T1,Gpa,U1,V1,Hpa,Ipa,W1,X1,Jpa,Kpa,Lpa,Mpa,b2,Opa,Ppa,Qpa,Rpa,e2,f2,g2,h2,j2,Tpa,k2,l2,Upa,Y1,$1;T1=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Gpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.j?$CLJS.Wk.j(g,l,n):$CLJS.Wk.call(null,g,l,n),$CLJS.mn.j?$CLJS.mn.j(g,l,n):$CLJS.mn.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.h?$CLJS.Wk.h(g,l):$CLJS.Wk.call(null,g,l),$CLJS.mn.h?$CLJS.mn.h(g,l):$CLJS.mn.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.g?$CLJS.Wk.g(g):$CLJS.Wk.call(null,g),$CLJS.mn.g?$CLJS.mn.g(g):$CLJS.mn.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.A?$CLJS.Wk.A():$CLJS.Wk.call(null),$CLJS.mn.A?$CLJS.mn.A():$CLJS.mn.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Wk,n,q,u,v),$CLJS.R.R($CLJS.mn,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};U1=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};V1=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Hpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.MP,b),$CLJS.vu(2,2,c))};
Ipa=function(){var a=$CLJS.Qp.h($CLJS.PZ,null),b=$CLJS.py(a,$CLJS.Oaa,function(c){return $CLJS.Qo(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.cm,a,$CLJS.ql,f,$CLJS.du,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
W1=function(a){return Ipa()(a,$CLJS.Lg,$CLJS.Lg)};X1=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Xr($CLJS.cm.g(a));if($CLJS.m(b)){var e=T1($CLJS.fv.g(b),c);$CLJS.m(e)?(e=$CLJS.as(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:T1($CLJS.dv.g(b),c)}return null};
Jpa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.cm),d=$CLJS.M.h(a,$CLJS.Wk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.du,$CLJS.oba),f=$CLJS.M.j(b,$CLJS.jba,!0),g=$CLJS.M.h(b,$CLJS.ov),l=$CLJS.M.j(b,$CLJS.mv,$CLJS.kv),n=X1(a,$CLJS.ms.g(c),g,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.su.g(c),g,b);if($CLJS.m(n))return n;n=X1(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=X1(a,function(){var q=$CLJS.yp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.ms.g(c),
l,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.su.g(c),l,b);if($CLJS.m(n))return n;d=X1(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=X1(a,function(){var q=$CLJS.yp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?X1(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?X1(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),l,b):f};
Kpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.mn.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.cm),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.ov);l=$CLJS.M.j(l,$CLJS.mv,$CLJS.kv);g=$CLJS.ms.g(g);f=T1($CLJS.lv.g(g),f);f=$CLJS.m(f)?f:T1($CLJS.lv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Jpa(b,c)],null)};
Lpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ql);a=$CLJS.M.h(b,$CLJS.du);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.kba,$CLJS.yk),f=$CLJS.M.j(d,$CLJS.mba,Kpa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.yk,n);l=e.g?e.g(l):e.call(null,l);return Y1(g,c,q,l)},null,a):null};Mpa=function(a){return $CLJS.qg.h($CLJS.uZ(a),$CLJS.vZ(a))};
$CLJS.Z1=function(a,b){return $CLJS.Pu($CLJS.Y_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.lk,$CLJS.BL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.LL],null),b)};$CLJS.a2=function(){return $CLJS.Lm.h($1,$CLJS.l_($CLJS.G([$CLJS.m_,$CLJS.jG,$CLJS.n_,function(a,b){return $1.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
b2=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Zt,null,$CLJS.qn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Lu(2,c):$CLJS.Lu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?V1(2,c):V1(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.L0.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Om.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.$F($CLJS.Hb,l):l}):$CLJS.Dm.h(a,f)};
Opa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return b2(b,Spa,d)},a,Mpa(a))};
Ppa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.gZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Gpa(),$CLJS.ug($CLJS.Lm.j(c2,$CLJS.C,$CLJS.mn),$CLJS.du.g(W1(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=b2(d,f,g),n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.convert",n))){var q=$CLJS.OG.l($CLJS.G([$CLJS.SE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.OG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Lpa(W1(d));$CLJS.eG("metabase.lib.convert",n,
$CLJS.W_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.SE,y.call(x,$CLJS.G([B]))])),$CLJS.OG.l($CLJS.G([$CLJS.HE,$CLJS.X_($CLJS.C($CLJS.Az(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Opa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.gZ.g(a))-1))return a;b+=1}else a=$CLJS.Qm.R(a,$CLJS.gZ,$CLJS.U,b,d)}};
Qpa=function(a){var b=$CLJS.a2();return $CLJS.Pm.h(function(c){return $CLJS.F.h($CLJS.vP.g(c),"__join")?$CLJS.Qm.j(c,$CLJS.vP,b):c},a)};Rpa=function(a){return"string"===typeof $CLJS.YP.g(a)?$CLJS.Dm.h($CLJS.U.j(a,$CLJS.wZ,$CLJS.d0($CLJS.YP.g(a))),$CLJS.YP):a};e2=function(a,b){var c=$CLJS.M.j(a,b,d2);if($CLJS.F.h(c,d2))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[eqa,a,$CLJS.Zu,b],null));return c};
f2=function(){return $CLJS.Om.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};g2=function(a){return $CLJS.oh.j($CLJS.P,f2(),a)};h2=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Lm.h(f2(),$CLJS.Om.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.lk)})),a))};
j2=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.i2),c);a=$CLJS.F.h(a,$CLJS.ZI)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(h2(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nO,a,b],null):a};Tpa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Lm.h($CLJS.rg.g(function(b){return $CLJS.bG(b,$CLJS.TG)}),$CLJS.rg.g($CLJS.i2)),$CLJS.b0.g(a))};
k2=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.gZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.i2.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.kR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.NP,Tpa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.nZ.g(c)],null)},null,a));return $CLJS.m($CLJS.TQ.g(a))?$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)):a};
l2=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.v0.j(a,b,$CLJS.Lm.h($CLJS.i2,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.v0.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.i2),e)}):d;return $CLJS.PN(a,$CLJS.Pf([b,c]))};Upa=function(a){var b=$CLJS.wZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Dm.h(a,$CLJS.wZ),$CLJS.YP,["card__",$CLJS.p.g(b)].join("")):a};
Y1=function Y1(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=U1(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.jv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=U1(f,c),d=Y1.D?Y1.D(b,l,g,d):Y1.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lba],null),Y1.D?Y1.D(f,b,c,d):Y1.call(null,f,b,c,d)):$CLJS.m($CLJS.jv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.jv,!0],null))};$1=function $1(a){switch(arguments.length){case 1:return $1.g(arguments[0]);case 2:return $1.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$1.g=function(a){return $1.h(a,60)};$1.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.RZ.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};$1.v=2;
var d2=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Spa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),m2=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),n2=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),eqa=new $CLJS.N(null,"m","m",1632677161),o2=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var p2,q2,c2,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa,cqa,dqa;p2=$CLJS.P;q2=$CLJS.P;c2=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.MZ,null,$CLJS.wO,null,$CLJS.UO,null,$CLJS.OQ,null,$CLJS.FP,null,$CLJS.YR,null,$CLJS.MP,null],null),null);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.cj.g($CLJS.P);Ypa=$CLJS.cj.g($CLJS.P);Zpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.r2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.CH,$CLJS.Pj,Zpa,Vpa,Wpa,Xpa,Ypa);$CLJS.r2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.fW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.r2),d))}else b=a;return b});$CLJS.r2.o(null,$CLJS.LZ,function(a){return a});
$CLJS.r2.o(null,$CLJS.BZ,function(a){var b=$CLJS.r2.g($CLJS.MP.g(a)),c=$CLJS.Rf($CLJS.Pm.h(function(q){var u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);return $CLJS.Z1($CLJS.r2.g(q),u)},$CLJS.OQ.g(a))),d=$CLJS.oh.j($CLJS.P,$CLJS.Ho(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[q,u],null)}),b),e=$CLJS.oh.j($CLJS.P,$CLJS.Ho(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.HG);return new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null)}),b),f=q2,g=p2;q2=d;p2=e;try{var l=Hpa(Rpa(a),b,$CLJS.G([$CLJS.OQ,c])),n=$CLJS.Sb(function(q,u){return $CLJS.Gb($CLJS.M.h(q,u))?q:$CLJS.Qm.j(q,u,$CLJS.r2)},l,$CLJS.Em.l(c2,$CLJS.MP,$CLJS.G([$CLJS.OQ])));return $CLJS.m($CLJS.UO.g(n))?$CLJS.Qm.j(n,$CLJS.UO,Qpa):n}finally{p2=g,q2=f}});
$CLJS.r2.o(null,$CLJS.bZ,function(a){a=$CLJS.Qm.j($CLJS.Qm.j(a,$CLJS.jZ,$CLJS.r2),$CLJS.gZ,$CLJS.r2);var b=$CLJS.m($CLJS.wO.g(a))?$CLJS.Qm.j(a,$CLJS.wO,function(c){return $CLJS.xe(c)?$CLJS.Pm.h($CLJS.r2,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.vP.g(a))?$CLJS.U.j(b,$CLJS.vP,"__join"):b});$CLJS.r2.o(null,$CLJS.wG,function(a){return $CLJS.Pm.h($CLJS.r2,a)});
$CLJS.r2.o(null,$CLJS.yG,function(a){return $CLJS.m($CLJS.Wk.g(a))?Ppa($CLJS.Qm.j($CLJS.c0(a),$CLJS.gZ,function(b){return $CLJS.Pm.h($CLJS.r2,b)})):$CLJS.Ku(a,$CLJS.r2)});$CLJS.r2.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,b],null))});
$CLJS.r2.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.PN(a,new $CLJS.k(null,3,[$CLJS.tJ,$CLJS.CG,$CLJS.BP,$CLJS.Kl,$CLJS.fS,$CLJS.uL],null));var c=$CLJS.U.j;var d=$CLJS.lk.g(a);$CLJS.m(d)||(d=$CLJS.CG.g(a),d=$CLJS.m(d)?d:$CLJS.BL(b));a=c.call($CLJS.U,a,$CLJS.lk,d);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,a,b],null))});
$CLJS.r2.o(null,$CLJS.ZI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZI,$CLJS.Dm.h(c,$CLJS.Pj),$CLJS.Pm.h($CLJS.r2,b)],null);b=$CLJS.fW(b);return null!=a?$CLJS.kf.h(b,$CLJS.r2.g(a)):b});$CLJS.r2.o(null,$CLJS.zG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.r2.o(null,$CLJS.MP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,e2(q2,c)],null))});$CLJS.r2.o(null,$CLJS.nO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.r2.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.fn.l($CLJS.G([c,a]))],null),d)});$pa=$CLJS.cj.g($CLJS.P);aqa=$CLJS.cj.g($CLJS.P);bqa=$CLJS.cj.g($CLJS.P);
cqa=$CLJS.cj.g($CLJS.P);dqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.DH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.i2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.CH,$CLJS.Pj,dqa,$pa,aqa,bqa,cqa);
$CLJS.i2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.i2,d);a=h2(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.i2,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Ku(g2(a),$CLJS.i2):
a;return a});for(var s2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[o2,n2],null)),t2=null,u2=0,v2=0;;)if(v2<u2){var fqa=t2.$(null,v2);$CLJS.lV(fqa,m2);v2+=1}else{var w2=$CLJS.A(s2);if(w2){var x2=w2;if($CLJS.re(x2)){var y2=$CLJS.$c(x2),gqa=$CLJS.ad(x2),hqa=y2,iqa=$CLJS.E(y2);s2=gqa;t2=hqa;u2=iqa}else{var jqa=$CLJS.C(x2);$CLJS.lV(jqa,m2);s2=$CLJS.D(x2);t2=null;u2=0}v2=0}else break}
for(var z2=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.kD,$CLJS.CJ,$CLJS.eK,$CLJS.MI,$CLJS.Yl,$CLJS.uK,$CLJS.qk,$CLJS.mJ,$CLJS.YJ,$CLJS.zK,$CLJS.sK,$CLJS.NK],null)),A2=null,B2=0,C2=0;;)if(C2<B2){var kqa=A2.$(null,C2);$CLJS.lV(kqa,o2);C2+=1}else{var D2=$CLJS.A(z2);if(D2){var E2=D2;if($CLJS.re(E2)){var F2=$CLJS.$c(E2),lqa=$CLJS.ad(E2),mqa=F2,nqa=$CLJS.E(F2);z2=lqa;A2=mqa;B2=nqa}else{var oqa=$CLJS.C(E2);$CLJS.lV(oqa,o2);z2=$CLJS.D(E2);A2=null;B2=0}C2=0}else break}
for(var G2=$CLJS.A($CLJS.Hg([$CLJS.lm,$CLJS.xu,$CLJS.mm,$CLJS.PK,$CLJS.ZI,$CLJS.QJ,$CLJS.tI,$CLJS.RK,$CLJS.ZH,$CLJS.oJ,$CLJS.jI,$CLJS.GJ,$CLJS.VJ,$CLJS.mI,$CLJS.iJ,$CLJS.rJ,$CLJS.YD,$CLJS.FI,$CLJS.DJ,$CLJS.uI,$CLJS.cK,$CLJS.EI,$CLJS.YH,$CLJS.aK,$CLJS.wK,$CLJS.gK,$CLJS.DI,$CLJS.SK,$CLJS.dK,$CLJS.LK,$CLJS.oI,$CLJS.aI,$CLJS.VK,$CLJS.YW,$CLJS.OJ,$CLJS.iD,$CLJS.BK,$CLJS.GK,$CLJS.YK,$CLJS.Ru,$CLJS.$u],!0)),H2=null,I2=0,J2=0;;)if(J2<I2){var pqa=H2.$(null,J2);$CLJS.lV(pqa,n2);J2+=1}else{var K2=$CLJS.A(G2);
if(K2){var L2=K2;if($CLJS.re(L2)){var M2=$CLJS.$c(L2),qqa=$CLJS.ad(L2),rqa=M2,sqa=$CLJS.E(M2);G2=qqa;H2=rqa;I2=sqa}else{var tqa=$CLJS.C(L2);$CLJS.lV(tqa,n2);G2=$CLJS.D(L2);H2=null;I2=0}J2=0}else break}$CLJS.i2.o(null,m2,function(a){return j2(a)});$CLJS.i2.o(null,$CLJS.yG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Lm.h(f2(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.i2.g(b)],null)})),a)});
$CLJS.i2.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=h2(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,e2(p2,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.i2.o(null,$CLJS.wG,function(a){return $CLJS.Pm.h($CLJS.i2,a)});
$CLJS.i2.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,$CLJS.i2.g(a),h2(b)],null)});
$CLJS.i2.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=h2($CLJS.PN(b,new $CLJS.k(null,3,[$CLJS.CG,$CLJS.tJ,$CLJS.Kl,$CLJS.BP,$CLJS.uL,$CLJS.fS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,a,b],null)});
$CLJS.i2.o(null,$CLJS.bZ,function(a){var b=g2(a);a=0==$CLJS.vP.g(a).lastIndexOf("__join",0)?$CLJS.Dm.h(b,$CLJS.vP):b;return $CLJS.fn.l($CLJS.G([$CLJS.Ku($CLJS.Dm.l(a,$CLJS.gZ,$CLJS.G([$CLJS.jZ])),$CLJS.i2),l2($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.jZ],null)),$CLJS.jZ,$CLJS.$R),k2(a)]))});
$CLJS.i2.o(null,$CLJS.BZ,function(a){var b=p2;p2=$CLJS.oh.j($CLJS.P,$CLJS.Ho(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.MP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.v0.j(c,d,$CLJS.i2)},l2($CLJS.v0.j($CLJS.v0.j(Upa(g2(a)),$CLJS.MP,function(c){return $CLJS.Pm.h(j2,c)}),$CLJS.OQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.i2.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Z_(v),$CLJS.F.h($CLJS.ql,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.i2.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Z_(q),$CLJS.F.h($CLJS.ql,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.MZ,$CLJS.IO),$CLJS.Em.l(c2,$CLJS.MP,$CLJS.G([$CLJS.MZ,$CLJS.OQ])))}finally{p2=b}});$CLJS.i2.o(null,$CLJS.HZ,function(a){return $CLJS.Ku(g2(a),$CLJS.i2)});
$CLJS.i2.o(null,$CLJS.LZ,function(a){var b=g2(a),c=$CLJS.NO.g(b),d=k2(b),e=$CLJS.F.h($CLJS.rl.g($CLJS.$d($CLJS.gZ.g(a))),$CLJS.HZ)?$CLJS.TQ:$CLJS.uO;a=$CLJS.fn.l;b=$CLJS.Ku($CLJS.Dm.l(b,$CLJS.gZ,$CLJS.G([$CLJS.NO])),$CLJS.i2);d=$CLJS.Pf([$CLJS.Wk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.NO,c):d;return a.call($CLJS.fn,$CLJS.G([b,c]))});